import {
    DICTIONARY,
    DICTIONARY_SET,
    FORM_INPUT,
    FORM_SINGLE_OPTION,
    HIDDEN,
    NUMBER,
    STRING
} from "../../../../components/common/form/helper/formConstants";

export const REGION_ID = "regionId";
export const REGION_TRANSLATION_ID = "regionTranslationId";
export const REGION_CODE = "regionCode";
export const REGION_NAME = "regionName";
export const REGION_LOCALE = "regionLocale";
export const REGION_COUNTRY = "regionCountry";
export const REGION_CITIES = "regionCities";

export const REGION_METADATA = {
    dictionaryTitle: 'adminPanel.dictionaries.regions.title',
    backControllerName: 'regions',
    titlePrepositionalCase: 'adminPanel.dictionaries.regions.titlePrepositionalCase',
    isAllElementsPageAvailable: false,
    fields: {
        id: {
            title: REGION_ID,
            objectPath: 'id',
            type: NUMBER,
            formElement: HIDDEN,
            order: 0,
            visibleField: false
        },
        translationId: {
            title: REGION_TRANSLATION_ID,
            objectPath: 'translation.id',
            type: NUMBER,
            formElement: HIDDEN,
            order: 0,
            visibleField: false
        },
        name: {
            title: REGION_NAME,
            placeholder: 'adminPanel.dictionaries.regions.name',
            objectPath: 'translation.title',
            type: STRING,
            formElement: FORM_INPUT,
            order: 1,
            validation: {
                required: true
            }
        },
        code: {
            title: REGION_CODE,
            placeholder: 'form.common.fields.code',
            objectPath: 'code',
            type: STRING,
            formElement: FORM_INPUT,
            order: 2,
            codePrefix: 'REGION_',
            validation: {
                required: true
            },
            visibleField: false,
        },
        country: {
            title: REGION_COUNTRY,
            placeholder: 'adminPanel.dictionaries.regions.country',
            objectPath: 'country',
            type: DICTIONARY,
            formElement: FORM_SINGLE_OPTION,
            optionsSource: 'countries/',
            order: 3,
            validation: {
                required: true
            }
        },
        cities: {
            title: REGION_CITIES,
            placeholder: 'adminPanel.dictionaries.regions.cities',
            objectPath: 'cities',
            type: DICTIONARY_SET,
            optionsSource: 'cities/',
            order: 4
        },
        locale: {
            title: REGION_LOCALE,
            objectPath: 'translation.locale',
            type: STRING,
            visibleField: false
        }
    }
}