/**
 * Общие константы форм
 */

export const STRING = 'string';
export const NUMBER = 'number';
export const BOOLEAN = 'boolean';
export const BOOLEAN_TRUE = 'true';
export const BOOLEAN_FALSE = 'false';
export const ENUM = 'enum';
export const ENUM_SET = 'enumSet';
export const DICTIONARY = 'dictionary';

//список, элементы которого являются значениями справочника
export const DICTIONARY_SET = 'dictionarySet';

//селект с единственным значением
export const SINGLE_OPTION = 'single_option'

// количество файлов, которых за раз может добавить пользователь
export const FILES_MAX_NUMBER = 10;

//кастомный инпут с марджином справа и уменьшенным шрифтом
export const CUSTOM_INPUT_CLASS = 'form__input right-margin-20 small-fontsize';
export const INPUT_RANGE_CLASS = 'form__input range';
export const ACTION_ADD = 'ADD';
export const ACTION_UPDATE = 'UPDATE';


//******************************************************

//селект с единственным значением
export const FORM_SINGLE_OPTION = 'singleOption'
export const FORM_MULTI_OPTIONS = 'multiOptions'
export const FORM_INPUT = 'input';
export const FORM_NUMBER_INPUT = 'numberInput';
export const FORM_TEXTAREA = 'textArea';

//список кнопок radio
export const FORM_RADIO_SET = 'radioSet';
/**
 * список чекбоксов
 */
export const FORM_CHECKBOX_SET = 'checkboxSet';

//для паролей
export const HIDDEN = 'hidden';
