import React from 'react';
import BreedInfoBlock from "../../breedinfo/BreedInfoBlock";
import BreedInfoBlockPreview from "../../breedinfo/BreedInfoBlockPreview";
import BreedInfoSection from "../../breedinfo/BreedInfoSection";
import InformationBlock from "../../../../../components/common/ui/text/informationblock/InformationBlock";
import {hasValue, isEmptyOrNull} from "../../../../../app/helper/commonHelper";
import {EMPTY} from "../../../../../app/const/appConst";
import {Link} from "react-router-dom";
import {DISEASE_METADATA} from "../../../../adminpanel/dictionaries/metadata/diseaseMetadata";
import {useTranslation} from "react-i18next";

/**
 * Раздел карты породы "Здоровье"
 */
const BreedHealthBlock = ({diseases}) => {
    const {t} = useTranslation();

    function formInfoMap(breedDisease) {
        const infoMap = [];
        if (hasValue(breedDisease.occurrenceCauses)) {
            infoMap.push({
                title: t('adminPanel.dictionaries.breedDisease.causes'),
                value: breedDisease.occurrenceCauses.map(el => el.name).join(', '),
                hasValue: true
            })
        }
        if (hasValue(breedDisease.prevalence)) {
            infoMap.push({
                title: t('adminPanel.dictionaries.breedDisease.prevalence'),
                value: breedDisease.prevalence.name,
                hasValue: true
            })
        }
        if (hasValue(breedDisease.averageAgesOfOnset)) {
            infoMap.push({
                title: t('adminPanel.dictionaries.breedDisease.agesOfOnset'),
                value: breedDisease.averageAgesOfOnset.map(el => el.name).join(', '),
                hasValue: true
            })
        }
        if (hasValue(breedDisease.translation?.description)) {
            infoMap.push({
                title: t('adminPanel.dictionaries.disease.description'),
                value: breedDisease.translation.description,
                hasValue: true
            })
        }
        if (hasValue(breedDisease.transmittedForHumans)) {
            infoMap.push({
                title: t('adminPanel.dictionaries.disease.transmittedForHumans'),
                value: breedDisease.transmittedForHumans ? t('common.yes') : t('common.no'),
                hasValue: true
            })
        }
        if (hasValue(breedDisease.diseaseId)) {
            infoMap.push({
                title: EMPTY,
                value: formDiseaseLink(breedDisease.diseaseId),
                hasValue: true
            })
        }
        return infoMap;
    }

    function formDiseaseLink(diseaseId) {
        return <Link key={diseaseId} to={`/${DISEASE_METADATA.backControllerName}/${diseaseId}`}
                     className="goto__link">{t('common.readMore')}</Link>
    }

    function getBreedDiseases() {
        return [...diseases].sort((a, b) => a.translation.title.localeCompare(b.translation.title))
            .map(breedDisease => {
                return (<BreedInfoSection key={breedDisease.id} title={breedDisease.translation.title}>
                    <InformationBlock infoMap={formInfoMap(breedDisease)} withBorders={false}/>
                </BreedInfoSection>);
            });
    }

    if (isEmptyOrNull(diseases)) {
        return null;
    }

    return (
        <BreedInfoBlock>
            <BreedInfoBlockPreview title={t('adminPanel.dictionaries.breedDisease.specificDiseases')}>
                {getBreedDiseases()}
            </BreedInfoBlockPreview>
            <br/>
        </BreedInfoBlock>
    );
};

export default BreedHealthBlock;