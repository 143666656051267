import {
    DICTIONARY,
    DICTIONARY_SET,
    FORM_INPUT,
    FORM_SINGLE_OPTION,
    HIDDEN,
    NUMBER,
    STRING
} from "../../../../components/common/form/helper/formConstants";

export const COUNTRY_ID = "countryId";
export const COUNTRY_TRANSLATION_ID = "countryTranslationId";
export const COUNTRY_CODE = "countryCode";
export const COUNTRY_NAME = "countryName";
export const COUNTRY_LOCALE = "countryLocale";
export const COUNTRY_CONTINENT = "countryContinent";
export const COUNTRY_ALPHA2_CODE = "countryAlpha2Code";
export const COUNTRY_ALPHA3_CODE = "countryAlpha3Code";
export const COUNTRY_ISO_CODE = "countryIsoCode";
export const COUNTRY_REGIONS = "countryRegions";

export const COUNTRY_METADATA = {
    dictionaryTitle: 'adminPanel.dictionaries.countries.title',
    backControllerName: 'countries',
    titlePrepositionalCase: 'adminPanel.dictionaries.countries.titlePrepositionalCase',
    isAllElementsPageAvailable: false,
    fields: {
        id: {
            title: COUNTRY_ID,
            objectPath: 'id',
            type: NUMBER,
            formElement: HIDDEN,
            order: 0,
            visibleField: false
        },
        translationId: {
            title: COUNTRY_TRANSLATION_ID,
            objectPath: 'translation.id',
            type: NUMBER,
            formElement: HIDDEN,
            order: 0,
            visibleField: false
        },
        name: {
            title: COUNTRY_NAME,
            placeholder: 'adminPanel.dictionaries.countries.name',
            objectPath: 'translation.title',
            type: STRING,
            formElement: FORM_INPUT,
            order: 1,
            validation: {
                required: true
            }
        },
        code: {
            title: COUNTRY_CODE,
            placeholder: 'form.common.fields.code',
            objectPath: 'code',
            type: STRING,
            formElement: FORM_INPUT,
            order: 2,
            codePrefix: 'COUNTRY_',
            validation: {
                required: true
            },
            visibleField: false,
        },
        continent: {
            title: COUNTRY_CONTINENT,
            placeholder: 'adminPanel.dictionaries.countries.continent',
            objectPath: 'continent',
            type: DICTIONARY,
            formElement: FORM_SINGLE_OPTION,
            optionsSource: 'continents/',
            order: 3,
            validation: {
                required: true
            }
        },
        alpha2Code: {
            title: COUNTRY_ALPHA2_CODE,
            placeholder: 'adminPanel.dictionaries.countries.alpha2',
            objectPath: 'alpha2Code',
            type: STRING,
            formElement: FORM_INPUT,
            order: 4,
            validation: {
                required: true,
                exactLength: 2
            }
        },
        alpha3Code: {
            title: COUNTRY_ALPHA3_CODE,
            placeholder: 'adminPanel.dictionaries.countries.alpha3',
            objectPath: 'alpha3Code',
            type: STRING,
            formElement: FORM_INPUT,
            order: 5,
            validation: {
                required: true,
                exactLength: 3
            }
        },
        isoCode: {
            title: COUNTRY_ISO_CODE,
            placeholder: 'adminPanel.dictionaries.countries.iso',
            objectPath: 'isoCode',
            type: STRING,
            formElement: FORM_INPUT,
            order: 6,
            validation: {
                required: true
            }
        },
        regions: {
            title: COUNTRY_REGIONS,
            placeholder: 'adminPanel.dictionaries.countries.regions',
            objectPath: 'regions',
            type: DICTIONARY_SET,
            optionsSource: 'regions/',
            order: 7
        },
        locale: {
            title: COUNTRY_LOCALE,
            objectPath: 'translation.locale',
            type: STRING,
            visibleField: false
        }
    }
}