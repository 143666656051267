import React, {useEffect, useState} from 'react';
import {hasValue, isEmptyOrNull} from "../../../../../../app/helper/commonHelper";
import {ACTION_UPDATE} from "../../../helper/formConstants";
import {EMPTY} from "../../../../../../app/const/appConst";

/**
 * Компонент множественного выбора из списка checkbox'ов
 */
const CheckboxSet = ({options, register, field, action, getValues, setValue, headerTitle}) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const fieldName = hasValue(field) ? field.title : EMPTY;

    useEffect(() => {
        if (isEmptyOrNull(options)) {
            return;
        }
        if (ACTION_UPDATE === action) {
            if (isEmptyOrNull(getValues(fieldName))) {
                return;
            }
            const defaultValue = Object.entries(getValues(fieldName))
                .filter(([, value]) => value !== false)
                .map(([, value]) => value);
            setSelectedItems(defaultValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);

    function handleCheckboxChange(option) {
        if (isEmptyOrNull(option)) {
            return;
        }
        let newSelected = selectedItems;
        if (newSelected.some(prevOption => prevOption.id === option.id)) {
            newSelected = newSelected.filter(item => item.id !== option.id);
        } else {
            newSelected = [...selectedItems, option];
        }
        setValue(fieldName, newSelected);
        setSelectedItems(newSelected);
    }

    if (isEmptyOrNull(options)) {
        return null;
    }
    return (
        <div className="checkbox multiselect">
            <div className='checkbox-header'>
                <span>{headerTitle}</span>
            </div>
            {options.map(option => (
                <div key={option.id}>
                    <label className={`custom-checkbox ${selectedItems.some(item => item.id === option.id) ? 'green' : EMPTY}`}>
                        <input className="custom-checkbox-input"
                               type="checkbox"
                               value={option}
                               {...register(`${fieldName}${option.id}`)}
                               checked={selectedItems.some(item => item.id === option.id)}
                               onChange={() => handleCheckboxChange(option)}
                        />
                        <span className="custom-checkbox-box"></span>
                        {option.name}
                    </label>
                </div>
            ))}
        </div>
    );
};

export default CheckboxSet;