import React, {useEffect, useRef, useState} from 'react';
import {EMPTY} from "../../../../../../app/const/appConst";
import {LABEL_CLASSNAME_WITHOUT_VALUE} from "../../../helper/commonFormConstants";
import {hasValue, isEmptyOrNull, isString} from "../../../../../../app/helper/commonHelper";
import {movePlaceholderDown, movePlaceholderUp, removeItem, storeItem} from "../../../helper/formHelper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import FormItem from "./FormItem";
import FormError from "./FormError";
import {useFilteredItemsForInput} from "../../../hooks/useFilteredItemsForInput";

/**
 * Компонент формы "текстовый инпут с возможностью выбора нескольких опций"
 * Требования к опциям: наличие id или code (для справочников) + name (для отображения)
 *
 */
const TextInputMultiOptionsFormItem = ({
                                           fieldName, fieldOrder, register, options = [],
                                           setValue, getValues, placeholder, disabled = false,
                                           errors = null, isNeedToStore = true, setSelectedItem = null
                                       }) => {
    const inputRef = useRef(EMPTY);
    const [labelClass, setLabelClass] = useState(LABEL_CLASSNAME_WITHOUT_VALUE);
    // значение, которое отображается в инпуте
    const [inputValue, setInputValue] = useState(EMPTY);
    //выбранные элементы
    const [selectedItems, setSelectedItems] = useState([]);
    // возможные опции - options, из которых убрали выбранные значения
    const [possibleOptions, setPossibleOptions] = useState([]);
    // когда пользователь ввел символы, по которым мы не нашли подходящих опций
    const [emptyResult, setEmptyResult] = useState(false)
    //компонент отображения списка выбора
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);

    // фильтруем элементы
    const filteredPossibleOptions = useFilteredItemsForInput(possibleOptions, inputRef.current.value, setEmptyResult)

    /**
     * Регистрируем листенер скролла над выпадающим списком, чтобы не прокручивалась страница,
     * когда мы доходим до конца/начала списка
     */
    useEffect(() => {
        const node = dropdownRef.current;
        if (node) {
            // Добавляем слушатель события wheel с опцией passive: false
            node.addEventListener('wheel', handleWheel, {passive: false});

            // Убираем слушатель при размонтировании
            return () => {
                node.removeEventListener('wheel', handleWheel, {passive: false});
            };
        }
    }, []);

    /**
     * Получение всех опций
     */
    useEffect(() => {
        if (isEmptyOrNull(options)) {
            return;
        }
        //после того как получили опции нужно все ранее выбранные опции убрать из списка доступных к выбору
        // если не приехали данные о ранее выбранных опциях
        let selected = getValues(fieldName);
        if (isEmptyOrNull(selected) && options !== undefined) {
            setPossibleOptions(options);
            return;
        }
        // При создании нового элемента в setValue просечивается значение из стора в виде строки,
        // а при обновлении просечиваются объекты из БД. Отсюда и необходимость приведения к единому виду
        if (isString(selected)) {
            selected = JSON.parse(selected);
            setValue(fieldName, selected)
        }
        setPossibleOptions(options.filter(option => !selected.some(parsed => parsed.id === option.id)));
        setSelectedItems(selected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);

    /**
     * Добавить выбранную опцию в список
     */
    function addOption(value) {
        if (isEmptyOrNull(value)) {
            return
        }
        const updatedItems = [...selectedItems, value];
        // обновим массив доступных для выбора элементов
        setPossibleOptions(options.filter(option => !updatedItems.some(el => el.id === option.id)));
        //добавляем в массив выбранных опций
        setSelectedItems(updatedItems);
        //просечиваем в управляемый компонент хука формы
        setValue(fieldName, updatedItems)
        //сохраняем в стор при необходимости
        if (isNeedToStore) {
            storeItem(fieldName, JSON.stringify(updatedItems))
        }
        //зачищаем поле инпута
        setInputValue(EMPTY);
        //манипуляции с выпадающим списком
        setShowDropdown(false);
        movePlaceholder(showDropdown);
        resetRequiredErrorMessage();
    }

    /**
     * Удалить опцию из списка
     */
    function removeOption(option) {
        if (isEmptyOrNull(option)) {
            return;
        }
        const updatedOptions = selectedItems.filter(selectedItem => selectedItem.id !== option.id);
        setSelectedItems(updatedOptions);
        //добавляем элемент к доступным для выбора
        setPossibleOptions(prevState => [...prevState, option])
        //просечиваем в управляемый компонент хука формы
        setValue(fieldName, updatedOptions)

        if (isNeedToStore) {
            storeItem(fieldName, JSON.stringify(updatedOptions))
        }
    }

    /**
     * Из-за некорректной работы хука формы yup мы будем сбрасывать сообщение об обязательном наличии значения в поле
     * при необходимости, чтобы не вводить пользователя в заблуждение
     */
    function resetRequiredErrorMessage() {
        if (isEmptyOrNull(errors)) {
            return;
        }
        if (errors[fieldName] !== undefined &&
            hasValue(errors[fieldName].type) &&
            errors[fieldName].type === 'required') {
            errors[fieldName] = undefined;
        }
    }

    /**
     * Обработка события изменения в инпуте
     */
    function handleInputChange(val) {
        setInputValue(val);
        if (!showDropdown) {
            setShowDropdown(true);
        }
        movePlaceholder(showDropdown);
    }

    /**
     * Обработка события получения фокуса в инпуте
     */
    function handleInputFocus() {
        if (isEmptyOrNull(inputValue)) {
            setShowDropdown(true)
        }
        movePlaceholder(showDropdown);
    }

    const handleWheel = (event) => {
        const node = dropdownRef.current;
        if (node) {
            const {scrollTop, scrollHeight, clientHeight} = node;
            const atTop = scrollTop === 0;
            const atBottom = scrollTop + clientHeight === scrollHeight;
            if ((atTop && event.deltaY < 0) || (atBottom && event.deltaY > 0)) {
                event.preventDefault();
            }
        }
    };

    /**
     * Обработка события нажатия на треугольник
     */
    function handleArrowClick() {
        setShowDropdown(!showDropdown);
        movePlaceholder(!showDropdown);
    }

    /**
     * Обработка потери фокуса инпутом.
     */
    function handleOnBlur() {
        movePlaceholder(showDropdown);
    }

    /**
     * Смещать ли плейсхолдер в инпут при потере фокуса полем
     */
    function movePlaceholder(isDropdownOptionsShow) {
        if (!isDropdownOptionsShow && isEmptyOrNull(inputRef.current.value)) {
            movePlaceholderDown(setLabelClass)
        } else {
            movePlaceholderUp(setLabelClass);
        }
    }

    /**
     * Если значение в инпуте не пустое - отображаем корзину
     */
    function getTrash() {
        if (inputValue !== EMPTY) {
            return <div className="input-with-options__trash-wrapper" onClick={() => clearInputValue()}>
                <FontAwesomeIcon icon={faTrash} size={"xl"}/>
            </div>
        }
        return null;
    }

    /**
     * Очистка значения в инпуте
     */
    function clearInputValue() {
        if (isNeedToStore) {
            removeItem(fieldName);
        }
        setInputValue(EMPTY);
        setValue(fieldName, EMPTY);
        movePlaceholderDown(setLabelClass)
    }

    return (
        <FormItem>
            {/*выбранные элементы*/}
            <div
                className={`input-with-options-selected-items__wrapper ${isEmptyOrNull(selectedItems) ? 'hidden' : EMPTY}`}>
                {isEmptyOrNull(selectedItems) ? null : selectedItems.map((option) => (
                    <div className="input-with-options__selected-item" key={option.id}>
                        <div>
                            <span>{option.name}</span>
                            <div className="close_btn" onClick={() => removeOption(option)}>
                                <span></span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className='input-with-options-wrapper'>
                {/*Корзина для очистки значения в форме*/}
                {getTrash()}
                <label htmlFor={fieldName} className={labelClass}>{placeholder}</label>
                {/*Инпут*/}
                <input
                    id={fieldName}
                    type="text"
                    className="form__input"
                    {...register(fieldName)}
                    ref={inputRef}
                    value={inputValue}
                    onChange={(e) => handleInputChange(e.target.value)}
                    onFocus={handleInputFocus}
                    onBlur={handleOnBlur}
                    tabIndex={fieldOrder}
                    disabled={disabled}
                    autoComplete="off"
                />
                {/*Стрелка*/}
                <div className="input-field-arrow__wrapper" onClick={handleArrowClick}>
                    <div className={`input-field-arrow ${showDropdown ? EMPTY : 'collapsed'}`}></div>
                </div>
                {/*выпадающие элементы*/}
                <div className={`dropdown-menu ${showDropdown ? 'show' : EMPTY}`}>
                    <div className="dropdown-menu__content" ref={dropdownRef}>
                        {filteredPossibleOptions.map((option) => (
                            <div key={option.id} className={`dropdown-item ${emptyResult ? 'no-items' : EMPTY}`}
                                 onClick={() => addOption(option)}>
                                <span>{option?.name}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {errors === null ? null : errors[fieldName] ?
                <FormError title={errors[fieldName].message} additionalStyle="margin-30"/> : null}
        </FormItem>
    );
};

export default TextInputMultiOptionsFormItem;