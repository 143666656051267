import axios from "axios";
import {LOCAL} from "../app/const/main";

/**
 * Сервис по работе с пользователями
 */
export default class UserService {

    //отправить регистрационные данные пользователя
    static async registerUser(user) {
        return await axios.post(`${LOCAL}/users/new`, user);
    }

    //залогинить пользователя
    static async loginUser(user) {
        return await axios.post(`${LOCAL}/login`, user);
    }

    //забыл пароль
    static async forgotPassword(email) {
        return await axios.post(`${LOCAL}/forgot-password?email=${email}`);
    }

    //Проверка, что код для восстановления пароля валиден
    static async resetPasswordCheck(changePasswordCode) {
        return await axios.get(`${LOCAL}/reset-password/${changePasswordCode}`);
    }

    static async resetPassword(newPassword, resetCode) {
        return await axios.post(`${LOCAL}/reset-password?newPassword=${newPassword}&resetCode=${resetCode}`);
    }


    // активировать пользователя
    static async activateUser(activationCode) {
        return await axios.post(`${LOCAL}/activate/${activationCode}`);
    }

    //получить список ролей пользователя
    static async getRoles(token) {
        return await axios.post(`${LOCAL}/roles`, {}, {headers: {'Authorization': 'Bearer_' + token}});
    }

    static async updateActivePet(petId, token) {
        return await axios.post(`${LOCAL}/pets/${petId}/update-active`,
            {}, {headers: {'Authorization': 'Bearer_' + token}});
    }

    static async updateUserName(userName, token) {
        return await axios.post(`${LOCAL}/profile/update-name?userName=${userName}`,
            {}, {headers: {'Authorization': 'Bearer_' + token}});
    }

    static async updateLocation(dto, token) {
        return await axios.post(`${LOCAL}/profile/update-location`,
            dto, {headers: {'Authorization': 'Bearer_' + token}});
    }

    static async updateLastname(lastname, token) {
        return await axios.post(`${LOCAL}/profile/update-lastname?lastname=${lastname}`,
            {}, {headers: {'Authorization': 'Bearer_' + token}});
    }

    static async saveUserPhoto(image, token) {
        const form = new FormData();
        form.append("image", image);
        return await axios.post(`${LOCAL}/profile/update-photo`, form,
            {
                headers: {
                    'Authorization': 'Bearer_' + token
                }
            });
    }
}