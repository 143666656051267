import React from 'react';
import LeftMenu from "./LeftMenu";
import {useSelector} from "react-redux";

/**
 * Блок контента
 */
const Content = ({children}) => {
    const isUserAuth = useSelector(state => state.userReducer.isUserAuth);
    return (
        <div className="content">
            <div className="content-flex-wrapper">
                {/*Меню слева пока не используется, поэтому временно скроем*/}
                {isUserAuth ? <LeftMenu/> : null}
                <div className="basic-information-block">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Content;