import React, {useEffect, useRef, useState} from 'react';
import {EMPTY} from "../../../../../../app/const/appConst";
import {LABEL_CLASSNAME_WITHOUT_VALUE} from "../../../helper/commonFormConstants";
import {hasValue, isEmptyOrNull} from "../../../../../../app/helper/commonHelper";
import {movePlaceholderDown, movePlaceholderUp, removeItem, storeItem} from "../../../helper/formHelper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import FormItem from "./FormItem";
import FormError from "./FormError";
import {useFilteredItemsForInput} from "../../../hooks/useFilteredItemsForInput";

/**
 * Компонент формы "Текстовый инпут с выпадающим списком опций"
 * с возможностью выбрать только один элемент
 *
 * Реализация заточена под DictionaryDto
 * @param action - характеризует состояние формы - добавляется элемент (ACTION_ADD) или изменяется (ACTION_UPDATE)
 * @param fieldName - имя поля
 * @param fieldOrder - порядок
 * @param register - из хука формы
 * @param options - возможные значения к выбору
 * @param setValue - из хука формы
 * @param getValues - из хука формы
 * @param placeholder - замещающий текст
 * @param disabled - признак недоступности компонента
 * @param errors - ошибки, возникающие на элементе формы (из хука формы)
 * @param isNeedToStore - нужно ли сохранять в стор
 * @param setSelectedItem - чтобы вернуть выбранный элемент вовне. Актуально,
 * когда требуется отслеживать выбранный элемент
 */
const TextInputSingleOptionFormItem = ({
                                           action, fieldName, fieldOrder, register, options,
                                           setValue, getValues, placeholder, disabled = false,
                                           errors = null, isNeedToStore = true, setSelectedItem = null
                                       }) => {
    const [labelClass, setLabelClass] = useState(LABEL_CLASSNAME_WITHOUT_VALUE);
    const [emptyResult, setEmptyResult] = useState(false)
    // значение, которое отображается в инпуте
    const [inputValue, setInputValue] = useState(EMPTY);
    //компонент отображения списка выбора
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);

    // фильтруем элементы
    const filteredItems = useFilteredItemsForInput(options, inputValue, setEmptyResult)

    /**
     * Регистрируем листенер скролла над выпадающим списком, чтобы не прокручивалась страница,
     * когда мы доходим до конца/начала списка
     */
    useEffect(() => {
        const node = dropdownRef.current;
        if (node) {
            // Добавляем слушатель события wheel с опцией passive: false
            node.addEventListener('wheel', handleWheel, {passive: false});

            // Убираем слушатель при размонтировании
            return () => {
                node.removeEventListener('wheel', handleWheel, {passive: false});
            };
        }
    }, []);

    /**
     * Изначальное просечивание значения в инпут
     * к нам приходит CODE
     */
    useEffect(() => {
        //если опции не загрузились, нам нечего делать
        if (isEmptyOrNull(options)) {
            return;
        }
        let initialValue = isEmptyOrNull(getValues(fieldName)) ? EMPTY : getValues(fieldName);
        setInitialValueIntoInput(initialValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);

    function setInitialValueIntoInput(value) {
        const option = options.find(el => el.id === value.id);
        if (hasValue(option)) {
            //просетим значение в инпут
            setInputValue(option.name);
            //обновим значение отправляемое наружу
            updateSelectedItem(value)
            movePlaceholder(true);
        }
    }

    /**
     * Обновить значение, отправляемое вовне компонента
     */
    function updateSelectedItem(value) {
        if (setSelectedItem !== null && setSelectedItem !== undefined) {
            //Если значение присутствует, сбросим сообщение об ошибке обязательности над полем
            resetRequiredErrorMessage();
            setSelectedItem(value);
        }
    }

    /**
     * Из-за некорректной работы хука формы yup мы будем сбрасывать сообщение об обязательном наличии значения в поле
     * при необходимости, чтобы не вводить пользователя в заблуждение
     */
    function resetRequiredErrorMessage() {
        if (errors[fieldName] !== undefined &&
            hasValue(errors[fieldName].type) &&
            errors[fieldName].type === 'required') {
            errors[fieldName] = undefined;
        }
    }


    /**
     * Обновить значение в инпуте
     */
    function updateSelectedOption(option) {
        if (isEmptyOrNull(option)) {
            //зануляем в хуке
            setValue(fieldName, EMPTY)
            //зануляем в переменной, которая смотрит наружу
            updateSelectedItem(EMPTY);

            if (action) {
                //зануляем в сторе
                removeItem(fieldName);
            }
            return;
        }

        if (isNeedToStore) {
            storeItem(fieldName, JSON.stringify(option))
        }
        //просечиваем в управляемый компонент хука формы
        setValue(fieldName, option)
        updateSelectedItem(option);
        //просечиваем текст в инпут
        setInputValue(option.name);
        setShowDropdown(false);
        movePlaceholder(showDropdown);
    }

    /**
     * Обработка события изменения в инпуте
     */
    function handleInputChange(val) {
        setInputValue(val);
        if (!showDropdown) {
            setShowDropdown(true);
        }
        movePlaceholder(showDropdown);
    }

    /**
     * Обработка события получения фокуса в инпуте
     */
    function handleInputFocus() {
        if (isEmptyOrNull(inputValue)) {
            setShowDropdown(true)
        }
        movePlaceholder(true);
    }

    const handleWheel = (event) => {
        const node = dropdownRef.current;
        if (node) {
            const {scrollTop, scrollHeight, clientHeight} = node;
            const atTop = scrollTop === 0;
            const atBottom = scrollTop + clientHeight === scrollHeight;
            if ((atTop && event.deltaY < 0) || (atBottom && event.deltaY > 0)) {
                event.preventDefault();
            }
        }
    };

    /**
     * Обработка события нажатия на треугольник
     */
    function handleArrowClick() {
        setShowDropdown(!showDropdown);
        movePlaceholder(!showDropdown);
    }

    /**
     * Обработка потери фокуса инпутом.
     */
    function handleOnBlur() {
        if (isEmptyOrNull(inputValue)) {
            movePlaceholder(true)
        }
    }

    /**
     * Смещать ли плейсхолдер в инпут при потере фокуса полем
     */
    function movePlaceholder(isDropdownOptionsShow) {
        if (!isDropdownOptionsShow) {
            movePlaceholderDown(setLabelClass)
        } else {
            movePlaceholderUp(setLabelClass);
        }
    }

    /**
     * Если значение в инпуте не пустое - отображаем корзину
     */
    function getTrash() {
        if (inputValue !== EMPTY) {
            return <div className="input-with-options__trash-wrapper" onClick={() => clearInputValue()}>
                <FontAwesomeIcon icon={faTrash} size={"xl"}/>
            </div>
        }
        return null;
    }

    /**
     * Очистка значения в инпуте
     */
    function clearInputValue() {
        if (isNeedToStore) {
            removeItem(fieldName);
        }
        setInputValue(EMPTY);
        setValue(fieldName, EMPTY);
        updateSelectedItem(EMPTY);
        movePlaceholderDown(setLabelClass)
    }

    return (
        <FormItem>
            <div className='input-with-options-wrapper'>
                {/*Корзина для очистки значения в форме*/}
                {getTrash()}
                <label htmlFor={fieldName} className={labelClass}>{placeholder}</label>
                {/*Инпут*/}
                <input
                    id={fieldName}
                    type="text"
                    className="form__input"
                    {...register(fieldName)}
                    value={inputValue}
                    onChange={(e) => handleInputChange(e.target.value)}
                    onFocus={handleInputFocus}
                    onBlur={handleOnBlur}
                    tabIndex={fieldOrder}
                    disabled={disabled}
                    autoComplete="off"
                />
                {/*Стрелка*/}
                <div className="input-field-arrow__wrapper" onClick={handleArrowClick}>
                    <div className={`input-field-arrow ${showDropdown ? EMPTY : 'collapsed'}`}></div>
                </div>
                {/*выпадающие элементы*/}
                <div className={`dropdown-menu ${showDropdown ? 'show' : EMPTY}`}>
                    <div className="dropdown-menu__content" ref={dropdownRef}>
                        {filteredItems.map((option) => (
                            <div key={option.id} className={`dropdown-item ${emptyResult ? 'no-items' : EMPTY}`}
                                 onClick={() => updateSelectedOption(option)}>
                                <span>{option?.name}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {errors === null ? null : errors[fieldName] ?
                <FormError title={errors[fieldName].message} additionalStyle="margin-30"/> : null}
        </FormItem>
    );
};

export default TextInputSingleOptionFormItem;