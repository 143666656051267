import {
    DICTIONARY,
    FORM_INPUT,
    FORM_SINGLE_OPTION,
    HIDDEN,
    NUMBER,
    STRING
} from "../../../../components/common/form/helper/formConstants";

export const CITY_ID = "cityId";
export const CITY_TRANSLATION_ID = "cityTranslationId";
export const CITY_CODE = "cityCode";
export const CITY_NAME = "cityName";
export const CITY_LOCALE = "cityLocale";
export const CITY_REGION = "cityRegion";

export const CITY_METADATA = {
    dictionaryTitle: 'adminPanel.dictionaries.cities.title',
    backControllerName: 'cities',
    titlePrepositionalCase: 'adminPanel.dictionaries.cities.titlePrepositionalCase',
    isAllElementsPageAvailable: false,
    fields: {
        id: {
            title: CITY_ID,
            objectPath: 'id',
            type: NUMBER,
            formElement: HIDDEN,
            order: 0,
            visibleField: false
        },
        translationId: {
            title: CITY_TRANSLATION_ID,
            objectPath: 'translation.id',
            type: NUMBER,
            formElement: HIDDEN,
            order: 0,
            visibleField: false
        },
        name: {
            title: CITY_NAME,
            placeholder: 'adminPanel.dictionaries.cities.name',
            objectPath: 'translation.title',
            type: STRING,
            formElement: FORM_INPUT,
            order: 1,
            validation: {
                required: true
            }
        },
        code: {
            title: CITY_CODE,
            placeholder: 'form.common.fields.code',
            objectPath: 'code',
            type: STRING,
            formElement: FORM_INPUT,
            order: 2,
            codePrefix: 'CITY_',
            validation: {
                required: true
            },
            visibleField: false,
        },
        region: {
            title: CITY_REGION,
            placeholder: 'adminPanel.dictionaries.cities.region',
            objectPath: 'region',
            type: DICTIONARY,
            formElement: FORM_SINGLE_OPTION,
            optionsSource: 'regions/',
            order: 3,
            validation: {
                required: true
            }
        },
        locale: {
            title: CITY_LOCALE,
            objectPath: 'translation.locale',
            type: STRING,
            visibleField: false
        }
    }
}