import {useMemo} from 'react';
import {isEmptyOrNull} from "../../../../app/helper/commonHelper";

const emptyResult = [{id: -1, title: 'Ничего не нашли'}];

/**
 * Выводит отфильтрованный исходный список по значению filterValue
 * отсортированный по полю title в алфавитном порядке
 */
export const useFilteredItemsForInput = (options, filterValue, setEmptyResult) => {
    const sortedItems = useSortedItems(options, setEmptyResult);
    return useMemo(() => {
        if (isEmptyOrNull(options)) {
            return [];
        }
        if (filterValue === null) {
            updateEmptyResultOption(setEmptyResult, sortedItems);
            return sortedItems;
        }
        if (filterValue && filterValue.length > 1) {
            const filteredItems = sortedItems.filter(item =>
                item.name.toLowerCase().includes(filterValue.toLowerCase())
            );

            updateEmptyResultOption(setEmptyResult, filteredItems);
            if (isEmptyOrNull(filteredItems)) {
                return emptyResult;
            } else {
                return filteredItems;
            }
        }
        updateEmptyResultOption(setEmptyResult, sortedItems);
        return sortedItems;
    }, [options, filterValue]);
}

function updateEmptyResultOption(setEmptyResult, objectToCheck) {
    if (isEmptyOrNull(objectToCheck)) {
        setEmptyResult(true);
    } else {
        setEmptyResult(false);
    }
}

export const useSortedItems = (options) => {
    return useMemo(() => {
        if (isEmptyOrNull(options)) {
            return emptyResult;
        }
        return [...options].sort((a, b) => a.name.localeCompare(b.name));
    }, [options]);
}



