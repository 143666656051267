import {EMPTY} from "../../../../app/const/appConst";
import {useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {getLanguageShortName} from "../../../../utils/langUtils";
import {hasValue, isEmptyOrNull} from "../../../../app/helper/commonHelper";
import * as yup from "yup";
import {
    ACTION_ADD,
    ACTION_UPDATE,
    BOOLEAN,
    BOOLEAN_FALSE,
    BOOLEAN_TRUE,
    DICTIONARY,
    DICTIONARY_SET,
    ENUM,
    ENUM_SET,
    FORM_RADIO_SET,
    NUMBER,
    STRING
} from "../../form/helper/formConstants";
import {validateNameRegEx} from "../../../../utils/stringutils";
import {
    clearSessionDataForDictionary,
    getDefaultValueForType,
    getStoredItem,
    hasStoredItem,
    storeItem
} from "../../form/helper/formHelper";
import {get} from "lodash";
import {getAllRecordDataById, getPossibleValues, saveRecord} from "../../../../service/dictionaryService";
import {useEffect, useState} from "react";

function useDictionaryTranslations(metadata, t, i18n, params, token, navigate, location) {
    const [dictionaryItem, setDictionaryItem] = useState({});
    const [action, setAction] = useState(EMPTY);
    const [errorMessage, setErrorMessage] = useState(EMPTY);
    const [submitErrorMessage, setSubmitErrorMessage] = useState(EMPTY);
    const [error, setError] = useState(false);
    const [disabledSubmit, setDisabledSubmit] = useState(false);
    const [loading, setIsLoading] = useState(false);
    const [possibleValues, setPossibleValues] = useState({});
    const [pageTitle, setPageTitle] = useState(EMPTY);
    const isAdmin = useSelector(state => state.userReducer.isAdmin);
    const isModerator = useSelector(state => state.userReducer.isModerator);

    const {
        register,
        formState: {errors},
        handleSubmit,
        setValue,
        getValues
    } = useForm({
        resolver: yupResolver(createValidationScheme(metadata.fields, t)),
        mode: "onBlur",
        reValidateMode: "onBlur"
    })

    const language = getLanguageShortName(i18n.language);

    /**
     * Валидация полей формы.
     * Основана на validation из metadat'ы полей справочника
     */
    function createValidationScheme(fields, t) {
        const schemaFields = {};

        if (isEmptyOrNull(fields)) {
            return yup.object().shape(schemaFields);
        }
        Object.entries(fields).filter(([, value]) => value.hasOwnProperty('validation'))
            .forEach(([, field]) => {
                let validator;
                if (NUMBER === field.type) {
                    validator = yup.number()
                } else if (STRING === field.type) {
                    validator = yup.string()
                } else {
                    return;
                }
                Object.entries(field.validation).forEach(([validatorTitle, validatorValue]) => {
                    if ('required' === validatorTitle) {
                        validator = validator.required(t("form.common.errors.fieldRequired"))
                    }
                    if ('exactLength' === validatorTitle) {
                        validator = validator.length(Number(validatorValue),
                            `Должно быть ровно ${Number(validatorValue)} символов`)
                    }
                    if ('email' === validatorTitle) {
                        validator = validator.email(t("form.common.errors.emailIncorrectFormat"))
                    }
                    if ('minLength' === validatorTitle) {
                        validator = validator.min(Number(validatorValue),
                            `Необходимо минимум ${Number(validatorValue)} символов`)
                    }
                    if ('maxLength' === validatorTitle) {
                        validator = validator.max(Number(validatorValue),
                            `Максимально должно быть ${Number(validatorValue)} символов`)
                    }
                    if ('nameFormat' === validatorTitle) {
                        validator = validator.matches(validateNameRegEx);
                    }
                })
                schemaFields[field.title] = validator;
            });
        return yup.object().shape(schemaFields);
    }

    /**
     * Действия при добавлении элемента:
     * построение полей формы, работа со стором
     */
    function initAdd() {
        setValuesFromStore();
        setCodePrefix();
    }

    /**
     * Просечиваем данные в поля формы из стора
     */
    function setValuesFromStore() {
        Object.entries(metadata.fields)
            .filter(([, field]) => field.hasOwnProperty('formElement'))
            .forEach(([, field]) => {
                setValue(field.title, getStoredItem(field.title, field.type))
            })
    }

    /**
     * Просечиваем префикс в поле кода если не найдено значение в сторе.
     * Сделано для удобства, чтобы не вспоминать с чего должен начинаться код.
     */
    function setCodePrefix() {
        if (isEmptyOrNull(metadata.fields.code) || isEmptyOrNull(metadata.fields.code.codePrefix)) {
            return
        }
        if (hasStoredItem(metadata.fields.code.title)) {
            return
        }
        setValue(metadata.fields.code.title, metadata.fields.code.codePrefix);
    }

    /**
     Действия при обновлении элемента справочника:
     построение полей формы, просечивание полей из БД
     */
    function initUpdate(item) {
        if (isEmptyOrNull(item)) {
            return;
        }
        Object.entries(metadata.fields)
            .filter(([, field]) => field.hasOwnProperty('formElement'))
            .forEach(([, field]) => {
                if (BOOLEAN === field.type) {
                    const booleanValue = get(item, field.objectPath, getDefaultValueForType(BOOLEAN))
                    setValue(field.title, booleanValue === true ? BOOLEAN_TRUE : BOOLEAN_FALSE)
                } else if (ENUM === field.type) {
                    const enumObj = get(item, field.objectPath, getDefaultValueForType(field.type))
                    setValue(field.title, enumObj.id)
                } else if (ENUM_SET === field.type) {
                    const initial = get(item, field.objectPath, [])
                    setValue(field.title, initial)
                } else if (DICTIONARY === field.type) {
                    const dictionaryObj = get(item, field.objectPath, getDefaultValueForType(field.type))
                    setValue(field.title, dictionaryObj)
                } else if (DICTIONARY_SET === field.type) {
                    const initial = get(item, field.objectPath, [])
                    setValue(field.title, initial)
                } else {
                    setValue(field.title, get(item, field.objectPath, getDefaultValueForType(field.type)));
                }
            })
    }

    /**
     * Получение элемента справочника
     */
    function getDictionaryElement() {
        setIsLoading(true);
        if (isEmptyOrNull(params.id)) {
            setAction(ACTION_ADD);
            setPageTitle(`. ${t('page.actions.add')}`)
            initAdd();
        } else {
            setPageTitle(`. ${t('page.actions.edit')}`)
            setAction(ACTION_UPDATE);

            // в компонент может передаться редактируемый элемент с формы просмотра элемента справочника.
            // в этом случае его не нужно запрашивать повторно
            if (isEmptyOrNull(location.state) || location.state.dictionaryEditingItem) {
                const response = getAllRecordDataById(Number(params.id), metadata.backControllerName, token);
                response.then((resp) => {
                    const data = resp.data.data;
                    setDictionaryItem(data);
                    initUpdate(data);
                }, (error) => {
                    setErrorMessage(error.response?.data?.messages ?
                        error.response?.data?.messages?.ERROR[0] : error.message)
                })
            } else {
                setDictionaryItem(location.state.dictionaryEditingItem);
            }
        }
        setIsLoading(false);
    }

    /**
     * Получить возможные значения для выбора "Да/Нет"
     */
    function getBooleanPossibleValues() {
        return [{id: BOOLEAN_TRUE, name: t('common.yes')},
            {id: BOOLEAN_FALSE, name: t('common.no')},
        ]
    }

    /**
     * Загрузка всех вспомогательных элементов, требующихся для выбора значений в полях справочника.
     * Это может быть подгрузка enum'ов или связанных справочников.
     */
    function loadPossibleValues(fields) {
        setIsLoading(true);
        const fieldsNeedValues = Object.entries(fields)
            .filter(([, value]) => value.hasOwnProperty('optionsSource'))
            .filter(([, value]) => value.hasOwnProperty('formElement'));

        const values = Promise.all(fieldsNeedValues.map(async ([key, value]) => {
            const options = await getPossibleValues(value.optionsSource, token);
            return {key, options: options.data.data};
        }));

        values.then((resp) => {
            resp.forEach((value) => {
                setPossibleValues(prevValues => ({
                    ...prevValues,
                    [value.key]: value.options
                }));
            }, {});
        }, () => {
            setErrorMessage("Произошла ошибка при получении возможных значений к полям справочника")
        })

        //просечиваем значения для булевых полей с formType = FORM_RADIO_SET
        Object.entries(fields)
            .filter(([, value]) => value.type === BOOLEAN)
            .filter(([, value]) => value.formElement === FORM_RADIO_SET)
            .forEach(booleanRadioSetField => {
                const fieldTitle = booleanRadioSetField[0];
                setPossibleValues(prevValues => ({
                    ...prevValues,
                    [fieldTitle]: getBooleanPossibleValues()
                }));
            });

        setIsLoading(false);
    }

    /**
     * Определение ссылки для редиректа после подтверждения отправки формы.
     * При добавлении элемента возвращаем к списку элементов
     * При редактировании эл-та возвращаем к редактируемому элементу спрвочника
     */
    function getBackToUrl() {
        if (ACTION_UPDATE === action && hasValue(params.id)) {
            return `/${metadata.backControllerName}/${params.id}`
        }
        return `/${metadata.backControllerName}`;
    }

    /**
     * Построение скелета дто по objectPath из metadata.fields для последующего заполнения
     */
    function initEmptyDto(fields, formData) {
        const result = {}; // Инициализация пустого результата
        // Проходим по всем значениям метаданных поля с использованием Object.values()
        Object.values(fields).forEach(field => {
            const value = formData[field.title]
            if (isEmptyOrNull(value)) {
                return;
            }
            // Разбиваем строку objectPath на массив ключей (возможна вложенность)
            const keys = field.objectPath.split('.');
            let current = result; // Указатель на текущий уровень в результирующем объекте

            // Проходим по каждому ключу в массиве keys
            keys.forEach((key, index) => {
                // Если ключ последний в массиве (индекс равен длине массива - 1)
                if (index === keys.length - 1) {
                    // Устанавливаем значение в результате для последнего ключа
                    // Проверяем тип поля для инициализации соответствующим значением
                    if (field.type === 'NUMBER') {
                        current[key] = 0; // Инициализируем числовое значение как 0
                    } else if (field.type === 'STRING') {
                        current[key] = ''; // Инициализируем строковое значение как пустую строку
                    } else {
                        current[key] = null; // Для всех других типов инициализируем null
                    }
                } else {
                    // Если ключ не последний, создаем вложенный объект, если он не существует
                    if (!current[key]) {
                        current[key] = {};
                    }
                    // Переходим на следующий уровень вложенности
                    current = current[key];
                }
            });
        });
        return result;
    }

    /**
     * Получение форматированного значения поля для последующей вставки в результирующий дто для отправки на бэк
     */
    function getValue(field, value) {
        if (isEmptyOrNull(value)) {
            return null;
        }
        // Если в метадате поле указано как boolean, но элемент формы - список из рэдио кнопок,
        // то перед отправкой нужно сконвертировать значение из фактического String -> Boolean
        if (BOOLEAN === field.type && FORM_RADIO_SET === field.formElement) {
            return value === BOOLEAN_TRUE
        }
        if (ENUM === field.type) {
            return {id: value}
        }
        if (ENUM_SET === field.type) {
            const result = [];
            value.forEach(el => result.push({id: el.id}));
            return result;
        }
        if (DICTIONARY === field.type) {
            return {code: value.code}
        }
        if (DICTIONARY_SET === field.type) {
            const result = [];
            value.forEach(el => result.push({code: el.code}));
            return result;
        }
        return value;
    }

    /**
     * Заполнение дто перед отправкой на бэк данными с формы
     */
    function fillData(fields, formData, result, language) {
        Object.values(fields).forEach(field => {
            const path = field.objectPath.split('.');
            let value = getValue(field, formData[field.title]);

            if (isEmptyOrNull(value)) {
                return;
            }
            let current = result;

            for (let i = 0; i < path.length - 1; i++) {
                const key = path[i];
                if (!current[key]) {
                    current[key] = {};
                }
                current = current[key];
            }
            current[path[path.length - 1]] = value;
        });
        fillLanguageData(result, language)
    }

    function fillLanguageData(result, language) {
        result.language = language;
        if (hasValue(result.translation)) {
            result.translation.locale = language;
        }
    }

    /**
     * Формирование дто отправляемой на бэк.
     * Состоит из:
     * - формирование скелета дто
     * - наполнение скелета данными
     */
    function formDto(fields, formData, language) {
        const result = initEmptyDto(fields, formData);
        fillData(fields, formData, result, language)
        return result;
    }

    function getOnBlurFunction() {
        if (EMPTY === action || ACTION_UPDATE === action) {
            return null
        }

        return storeItem
    }

    /**
     * Обработка события отправки формы
     */
    function submitForm(formData) {
        setDisabledSubmit(true);
        setIsLoading(true)
        const dto = formDto(metadata.fields, formData, language);
        const resp = saveRecord(dto, metadata.backControllerName, token)
        resp.then(
            () => {
                clearSessionDataForDictionary(metadata.fields, action);
                setError(false);
                setIsLoading(false);
                navigate(getBackToUrl());
            },
            (error) => {
                setIsLoading(false)
                setError(true)
                setDisabledSubmit(false);
                setSubmitErrorMessage(error.response?.data?.messages ?
                    error.response?.data?.messages?.ERROR[0] : error.message)
            })
    }

    /**
     * Если пользователь не является админом или модератором,
     * то ему не разрешается добавлять/изменять элементы справочника
     */
    function redirectIfNotAdminOrModerator() {
        if (isAdmin || isModerator) {
            return
        }
        navigate("/");
    }

    useEffect(() => {
        redirectIfNotAdminOrModerator();
        getDictionaryElement();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        //зачищаем сообщение об ошибках
        setErrorMessage(EMPTY);
        setSubmitErrorMessage(EMPTY);
        if (EMPTY === action) {
            return;
        }
        //если при обновлении элемента справочника мы не смогли получить элемент
        if (ACTION_UPDATE === action && isEmptyOrNull(dictionaryItem) && !loading) {
            setErrorMessage('Не смогли получить элемент справочника для обновления')
            return;
        }
        loadPossibleValues(metadata.fields)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action, dictionaryItem]);

    return {
        action,
        possibleValues,
        error,
        errors,
        getValues,
        setValue,
        register,
        getOnBlurFunction,
        errorMessage,
        pageTitle,
        handleSubmit,
        submitForm,
        submitErrorMessage,
        loading,
        disabledSubmit
    }
}

export default useDictionaryTranslations;