import React, {useEffect, useState} from 'react';
import TextInputSingleOptionFormItem from "../inputs/wrappers/TextInputSingleOptionFormItem";
import {ACTION_ADD} from "../../helper/formConstants";
import {USER_CITY, USER_COUNTRY, USER_REGION} from "../../helper/commonFormConstants";
import {hasValue, isEmptyOrNull} from "../../../../../app/helper/commonHelper";
import {EMPTY} from "../../../../../app/const/appConst";
import {
    getCitiesByRegionCode,
    getPossibleValues,
    getRegionsByCountryCode
} from "../../../../../service/dictionaryService";
import {COUNTRY_METADATA} from "../../../../../pages/adminpanel/dictionaries/metadata/countryMetadata";
import {REGION_METADATA} from "../../../../../pages/adminpanel/dictionaries/metadata/regionMetadata";
import {CITY_METADATA} from "../../../../../pages/adminpanel/dictionaries/metadata/cityMetadata";

/**
 * Часть формы, относящаяся к заполнению данных о локации пользователя
 */
const UserLocationFormPart = ({action = ACTION_ADD, errors, getValues, setValue, setErrorMessage, register}) => {
    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedCountryCode, setSelectedCountryCode] = useState(EMPTY);
    const [selectedRegionCode, setSelectedRegionCode] = useState(EMPTY);
    const [selectedCityCode, setSelectedCityCode] = useState(EMPTY);

    useEffect(() => {
        //получаем countries
        getCountriesOptions(setErrorMessage, setCountries, null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //подгружаем регионы
    useEffect(() => {
        //при смене страны зануляем города и выбранные ранее значения города и региона
        setCities([])
        setRegions([])
        setSelectedCityCode(EMPTY);
        setSelectedRegionCode(EMPTY);

        if (hasValue(selectedCountryCode)) {
            getRegionsOptions(setErrorMessage, setRegions, selectedCountryCode)
        }
    }, [selectedCountryCode]);

    useEffect(() => {
        //при смене региона зануляем город
        setCities([])
        setSelectedCityCode(EMPTY);

        if (hasValue(selectedRegionCode)) {
            getCitiesOptions(setErrorMessage, setCities, selectedRegionCode)
        }
    }, [selectedRegionCode]);
    function getCountriesOptions(setErrorMessage, setCountries, token) {
        const response = getPossibleValues(COUNTRY_METADATA.backControllerName, token);
        response.then((resp) => {
            setErrorMessage(EMPTY)
            setCountries(resp.data.data);
        }, (error) => {
            setErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message);
        });
    }

    function getRegionsOptions(setErrorMessage, setRegions, countryCode) {
        const response = getRegionsByCountryCode(REGION_METADATA.backControllerName, countryCode);
        response.then((resp) => {
            setErrorMessage(EMPTY)
            setRegions(resp.data.data);
        }, (error) => {
            setErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message);
        });
    }

    function getCitiesOptions(setErrorMessage, setCities, regionCode) {
        const response = getCitiesByRegionCode(CITY_METADATA.backControllerName, regionCode);
        response.then((resp) => {
            setErrorMessage(EMPTY)
            setCities(resp.data.data);
        }, (error) => {
            setErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message);
        });
    }

    return (
        <>
            {/*Страна*/}
            <TextInputSingleOptionFormItem action={action} errors={errors} fieldName={USER_COUNTRY} fieldOrder='4'
                                           register={register} placeholder="Страна" getValues={getValues}
                                           setValue={setValue} options={countries} disabled={isEmptyOrNull(countries)}
                                           setSelectedItem={setSelectedCountryCode}/>

            <div className={`extended-location-data ${isEmptyOrNull(regions) ? EMPTY : 'visible'}`}>
                {/*Регион*/}
                <TextInputSingleOptionFormItem action={action} errors={errors} fieldName={USER_REGION}
                                               fieldOrder='5'
                                               register={register} placeholder="Регион" getValues={getValues}
                                               setValue={setValue} options={regions} disabled={isEmptyOrNull(regions)}
                                               setSelectedItem={setSelectedRegionCode}/>
                {/*Город*/}
                <TextInputSingleOptionFormItem action={action} errors={errors} fieldName={USER_CITY} fieldOrder='6'
                                               register={register} placeholder="Город" getValues={getValues}
                                               setValue={setValue} options={cities} disabled={isEmptyOrNull(cities)}
                                               setSelectedItem={setSelectedCityCode}/>
            </div>
        </>
    );
};

export default UserLocationFormPart;