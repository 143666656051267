export const MAIN = "main";
export const MESSAGES = "messages";
export const BREED = "breed";
export const HEALTH_CARD = "healthCard";
export const USER_VETS = "userVets";

// меню верхнего уровня
export const TopMenuLinks = [
    {title: "menu.top.breeds", path: "/breeds", alias: BREED},
   /* {title: "Shelters", path: "#"},
    {title: "Pets-friendly map", path: "#"},
    {title: "Lost pets", path: "#"},*/
];

// левое меню для зарегистрированного пользователя
export const LeftMenuRegisterUserLinks = [
    // {title: "menu.left.main", path: "/", alias: MAIN},
    // {title: "menu.left.messages", path: "#", alias: MESSAGES},
    {title: "menu.left.healthCard", path: "/health-card", alias: HEALTH_CARD},
    {title: "menu.left.userVets", path: "/my-vets", alias: USER_VETS},
    /*{title: "menu.left.calendar", path: "#"},
    {title: "menu.left.healthCard", path: "#"},
    {title: "menu.left.nutritionDiary", path: "#"},
    {title: "menu.left.observationDiary", path: "#"},
    {title: "menu.left.needHelp", path: "#"}*/
];

export const LeftMenuPanel = [
    {title: "menu.panel.dashboard", path: "/panel/dashboard"},
    {title: "menu.panel.users", path: "/panel/users"},
    {title: "menu.panel.dictionaries", path: "/panel/dictionaries"},
    {title: "menu.panel.serviceInfo", path: "/panel/service-info"},
]