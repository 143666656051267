import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {setFirstName} from "../../../app/store/userReducer";
import PartInfoContent from "../../../components/common/form/abstract/PartInfoContent";
import UpdatableElementPreview from "../../../components/common/UpdatableElementPreview";
import TextInputFormItem from "../../../components/common/form/parts/inputs/wrappers/TextInputFormItem";
import FormError from "../../../components/common/form/parts/inputs/wrappers/FormError";
import FormSubmitButton from "../../../components/common/form/parts/inputs/FormSubmitButton";
import {userNameFormSchema} from "../helper/userHelper";
import {USER_NAME} from "../helper/userFormConstants";
import UserService from "../../../service/UserService";
import Form from "../../../components/common/form/abstract/Form";
import {isFieldFilled} from "../../../components/common/form/helper/formHelper";
import {EMPTY} from "../../../app/const/appConst";

/**
 * Компонент для работы с именем пользователя
 */
const UserName = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const userName = useSelector(state => state.userReducer.firstName);
    const token = useSelector(state => state.userReducer.token);
    const [errorMessage, setErrorMessage] = useState(EMPTY);
    const [nameErrorMessage, setNameErrorMessage] = useState(EMPTY);
    const [isLoading, setIsLoading] = useState(false);
    const {
        register,
        formState: {errors},
        handleSubmit,
        setValue,
        getValues,
        watch
    } = useForm({
        resolver: yupResolver(userNameFormSchema(t)),
        mode: "onBlur",
        reValidateMode: "onBlur"
    })

    function clearErrors() {
        setErrorMessage(EMPTY);
        setNameErrorMessage(EMPTY);
    }

    function isButtonActive() {
        return userName !== watch(USER_NAME) && watch(USER_NAME) !== EMPTY
    }

    function init() {
        setValue(USER_NAME, userName);
    }

    function submitForm(data) {
        if (!isButtonActive()) {
            return;
        }
        clearErrors();
        setIsLoading(true)
        const response = UserService.updateUserName(data.userName, token);
        response.then((resp) => {
            dispatch(setFirstName(resp.data.data))
        }, (error) => {
            setNameErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message)
        });
        setIsLoading(false)
    }

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
        clearErrors();
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <PartInfoContent errorMessage={errorMessage}>
            <UpdatableElementPreview title={t('page.profile.userName')} backTo='/profile'
                                     content={t('page.profile.canEditName')}/>
            <Form handleSubmit={handleSubmit} submitForm={submitForm}>
                <TextInputFormItem errors={errors} fieldName={USER_NAME} placeholder={t('common.user.name')}
                                   register={register} hasValue={isFieldFilled(getValues, USER_NAME)} tabIndex='1'/>
                <FormError title={nameErrorMessage}/>
                <FormSubmitButton title={t('button.save')} isLoading={isLoading} isButtonActive={isButtonActive}
                                  handleSubmit={handleSubmit} submitForm={submitForm}/>
            </Form>
        </PartInfoContent>
    );
};

export default UserName;