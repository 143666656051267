import React from 'react';
import Content from "../../components/common/Content";

/**
 * Ветеринарные контакты пользователя
 */
const UserVets = () => {
    return (
        <Content>
            Ветеринарные контакты пользователя
        </Content>
    );
};

export default UserVets;