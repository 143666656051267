import {
    BEHAVIOUR, BREED_ANIMAL_CLUB,
    BREED_APPEARANCE_ID,
    BREED_CARE_ID,
    BREED_CODE,
    BREED_COUNTRY_ID, BREED_CULTURAL_SIGNIFICANCE, BREED_DISEASES,
    BREED_FACTS, BREED_FIRST_MENTION, BREED_GLOBAL_PRESENCE, BREED_HISTORICAL_PERIOD,
    BREED_HISTORY,
    BREED_ID,
    BREED_IS_TECHNICAL, BREED_METADATA,
    BREED_TITLE,
    BREED_TRANSLATION_ID, BREEDING_PURPOSE,
    CARE_BATHING, CARE_DESCRIPTION,
    CARE_EAR_CLEANING,
    CARE_EYE_CLEANING,
    CARE_GROOMING,
    CARE_LEVEL,
    CARE_TEETH_CLEANING,
    FEMALE_LIFE_FROM,
    FEMALE_LIFE_RANGE_ID,
    FEMALE_LIFE_TO,
    FEMALE_SIZE_FROM,
    FEMALE_SIZE_RANGE_ID,
    FEMALE_SIZE_TO,
    FEMALE_WEIGHT_FROM,
    FEMALE_WEIGHT_RANGE_ID,
    FEMALE_WEIGHT_TO,
    HEALTH_ID,
    MALE_LIFE_FROM,
    MALE_LIFE_RANGE_ID,
    MALE_LIFE_TO,
    MALE_SIZE_FROM,
    MALE_SIZE_RANGE_ID,
    MALE_SIZE_TO,
    MALE_WEIGHT_FROM,
    MALE_WEIGHT_RANGE_ID,
    MALE_WEIGHT_TO,
    SOURCES
} from "./breedFormConstants";
import * as yup from "yup";
import {validateNameRegEx} from "../../../utils/stringutils";
import BreedService from "../api/BreedService";
import {storeItem} from "../../../components/common/form/helper/formHelper";
import {hasValue, isEmptyOrNull, isNotPersisted} from "../../../app/helper/commonHelper";
import {
    GENDER_FEMALE,
    GENDER_MALE,
    RANGE_TYPE_AGE,
    RANGE_TYPE_SIZE,
    RANGE_TYPE_WEIGHT
} from "../../../app/const/rangeConst";
import {MEASURE_TYPE_CENTIMETER, MEASURE_TYPE_KILO, MEASURE_TYPE_YEAR} from "../../../app/const/measureConst";
import {getAllRecordsInCurrentTranslation, getDictionaryEnums} from "../../../service/dictionaryService";
import {EMPTY} from "../../../app/const/appConst";
import {ANIMAL_CLUBS_METADATA} from "../../adminpanel/dictionaries/metadata/animalClubMetadata";

/**
 * Валидация формы с породами
 */
export function formBreedSchema(t) {
    return yup.object().shape({
        title: yup.string().required(t("form.common.errors.fieldRequired")).matches(validateNameRegEx),
        sizeFrom: yup.number().transform((value) => (isNaN(value) ? 0 : value)).min(0),
        sizeTo: yup.number().transform((value) => (isNaN(value) ? 0 : value)).nullable()
            .min(yup.ref("sizeFrom"), t("form.common.errors.valueGreaterOrEquals")), // больше или равно
        weightFrom: yup.number().transform((value) => (isNaN(value) ? 0 : value)).min(0),
        weightTo: yup.number().transform((value) => (isNaN(value) ? 0 : value)).positive().nullable()
            .min(yup.ref("weightFrom"), t("form.common.errors.valueGreaterOrEquals")), // больше или равно
        lifeFrom: yup.number().transform((value) => (isNaN(value) ? 0 : value)).min(0),
        lifeTo: yup.number().transform((value) => (isNaN(value) ? 0 : value)).positive().nullable()
            .min(yup.ref("lifeFrom"), t("form.common.errors.valueGreaterOrEquals")), // больше или равно
        breedFacts: yup.array()
            .of(
                yup.object().shape({
                    fact: yup.string().ensure().required(t("form.common.errors.fieldRequired"))
                })
            ),
        sources: yup.array()
            .of(
                yup.object().shape({
                    borrowedObject: yup.string().ensure().required(),
                    linkDescription: yup.string().ensure().required()
                })
            )
    });
}

/**
 * Заполнение дто данными перед отправкой на бэк
 */
export function formDto(data, language, animalKind) {
    const dto = {
        id: data[BREED_ID],
        code: data[BREED_CODE],
        language: language,
        animalKind: animalKind,
        historicalPeriod: data[BREED_HISTORICAL_PERIOD] === '' ? null : {id: data[BREED_HISTORICAL_PERIOD]},
        globalPresence: data[BREED_GLOBAL_PRESENCE] === '' ? null : {id: data[BREED_GLOBAL_PRESENCE]},
        technical: data[BREED_IS_TECHNICAL],
        //Заполнение по умолчанию. Далее мы посмотрим нужно ли обновлять картинку
        //Если происходит update, то данные возьмем из пришедшей dto
        filename: "",
        translation: {
            id: data[BREED_TRANSLATION_ID],
            locale: language,
            title: data[BREED_TITLE] === '' ? null : data[BREED_TITLE],
            breedHistory: data[BREED_HISTORY] === '' ? null : data[BREED_HISTORY],
            firstMention: data[BREED_FIRST_MENTION] === '' ? null : data[BREED_FIRST_MENTION],
            culturalSignificance: data[BREED_CULTURAL_SIGNIFICANCE] === '' ? null : data[BREED_CULTURAL_SIGNIFICANCE],
            behaviour: data[BEHAVIOUR] === '' ? null : data[BEHAVIOUR],
            care: data[CARE_DESCRIPTION] === '' ? null : data[CARE_DESCRIPTION],
            breedFacts: data[BREED_FACTS],
            sources: data[SOURCES]
        }
    };
    formCareSection(dto, data);
    formHealth(dto, data);
    formAppearance(dto, data);
    nullIdsForNewFactsAndSources(dto);
    fillCountry(dto, data);

    formAnimalClubs(dto, data);
    formDiseases(dto, data);
    formBreedingPurposes(dto, data);
    return dto;
}

/**
 * Для только что добавленных записей мы зануляем id,
 * чтобы не было проблем при поиске и сохранении сущности на бэке
 * здесь же айдишник нам нужен для того, чтобы мы могли итерироваться по списку
 */
function nullIdsForNewFactsAndSources(dto) {

    dto.translation.breedFacts?.forEach(fact => {
        if (typeof fact.id === 'string' || fact.id instanceof String) {
            fact.id = 0
        }
    })

    dto.translation.sources?.forEach(source => {
        if (typeof source.id === 'string' || source.id instanceof String) {
            source.id = 0
        }
    })
}

function formAnimalClubs(dto, data) {
    const animalClubs = data[BREED_ANIMAL_CLUB];
    if (animalClubs === null) {
        return;
    }
    const clubs = [];
    Object.entries(animalClubs)
        .filter(([, value]) => value !== false)
        .map(([, value]) => value)
        .forEach(el => clubs.push({code: el.code}));
    dto.clubs = clubs;
}

function formDiseases(dto, data) {
    const diseases = data[BREED_DISEASES];
    if (diseases === null) {
        return;
    }
    const dis = [];
    Object.entries(diseases)
        .filter(([, value]) => value !== false)
        .map(([, value]) => value)
        .forEach(el => dis.push({code: el.code}));
    dto.diseases = dis;
}

/**
 * Заполнение данных по целям разведения породы
 */
function formBreedingPurposes(dto, data) {
    const breedPurposes = data[BREEDING_PURPOSE];
    if (breedPurposes === null) {
        return;
    }
    const purposes = [];
    Object.entries(breedPurposes)
        .filter(([, value]) => value !== false)
        .map(([, value]) => value)
        .forEach(el => purposes.push({id: el.id}));
    dto.breedingPurposes = purposes;
}

/**
 * Заполнение данных по стране
 */
function fillCountry(dto, data) {
    if (hasValue(data[BREED_COUNTRY_ID])) {
        dto.country = {code: data[BREED_COUNTRY_ID].code}
    }
}

/**
 * Заполнение данных по уходу за питомцем
 */
function formCareSection(dto, data) {
    //Раздел Care может отсутствовать. Признаком отсутствия является незаполненность этих обязательных полей
    if (isNotPersisted(data[BREED_CARE_ID]) && isEmptyOrNull(data[CARE_LEVEL])) {
        return;
    }
    dto.breedCare = {
        id: data[BREED_CARE_ID],
        careLevel: {
            id: data[CARE_LEVEL]
        },
        groomingFrequency: {
            id: data[CARE_GROOMING]
        },
        bathingFrequency: {
            id: data[CARE_BATHING]
        },
        earCleaningFrequency: {
            id: data[CARE_EAR_CLEANING]
        },
        eyeCleaningFrequency: {
            id: data[CARE_EYE_CLEANING]
        },
        teethCleaningFrequency: {
            id: data[CARE_TEETH_CLEANING]
        }
    }
}

/**
 * Заполнение данных по здоровью
 */
function formHealth(dto, data) {
    dto.health = {
        id: data[HEALTH_ID]
    }
    fillMaleAge(dto.health, data);
    fillFemaleAge(dto.health, data);
}

/**
 * Заполнение данных по внешнему виду
 */
function formAppearance(dto, data) {
    dto.appearance = {
        id: data[BREED_APPEARANCE_ID]
    }
    fillMaleSize(dto.appearance, data)
    fillFemaleSize(dto.appearance, data)
    fillMaleWeight(dto.appearance, data)
    fillFemaleWeight(dto.appearance, data)
}

/**
 * Заполнение высоты в холке для самцов
 */
function fillMaleSize(appearanceDto, data) {
    if (data[MALE_SIZE_FROM] === 0 || data[MALE_SIZE_TO] === 0) {
        return;
    }
    appearanceDto.maleSize = {
        id: data[MALE_SIZE_RANGE_ID],
        from: data[MALE_SIZE_FROM] === 0 ? null : data[MALE_SIZE_FROM],
        to: data[MALE_SIZE_TO] === 0 ? null : data[MALE_SIZE_TO],
        measure: MEASURE_TYPE_CENTIMETER,
        rangeType: RANGE_TYPE_SIZE,
        gender: GENDER_MALE
    }
}

/**
 * Заполнение высоты в холке для самок
 */
function fillFemaleSize(appearanceDto, data) {
    if (data[FEMALE_SIZE_FROM] === 0 || data[FEMALE_SIZE_TO] === 0) {
        return;
    }
    appearanceDto.femaleSize = {
        id: data[FEMALE_SIZE_RANGE_ID],
        from: data[FEMALE_SIZE_FROM] === 0 ? null : data[FEMALE_SIZE_FROM],
        to: data[FEMALE_SIZE_TO] === 0 ? null : data[FEMALE_SIZE_TO],
        measure: MEASURE_TYPE_CENTIMETER,
        rangeType: RANGE_TYPE_SIZE,
        gender: GENDER_FEMALE
    }
}

/**
 * Заполнение веса для самцов
 */
function fillMaleWeight(appearanceDto, data) {
    if (data[MALE_WEIGHT_FROM] === 0 || data[MALE_WEIGHT_TO] === 0) {
        return;
    }
    appearanceDto.maleWeight = {
        id: data[MALE_WEIGHT_RANGE_ID],
        from: data[MALE_WEIGHT_FROM] === 0 ? null : data[MALE_WEIGHT_FROM],
        to: data[MALE_WEIGHT_TO] === 0 ? null : data[MALE_WEIGHT_TO],
        measure: MEASURE_TYPE_KILO,
        rangeType: RANGE_TYPE_WEIGHT,
        gender: GENDER_MALE
    }
}

/**
 * Заполнение веса для самок
 */
function fillFemaleWeight(appearanceDto, data) {
    if (data[FEMALE_WEIGHT_FROM] === 0 || data[FEMALE_WEIGHT_TO] === 0) {
        return;
    }
    appearanceDto.femaleWeight = {
        id: data[FEMALE_WEIGHT_RANGE_ID],
        from: data[FEMALE_WEIGHT_FROM] === 0 ? null : data[FEMALE_WEIGHT_FROM],
        to: data[FEMALE_WEIGHT_TO] === 0 ? null : data[FEMALE_WEIGHT_TO],
        measure: MEASURE_TYPE_KILO,
        rangeType: RANGE_TYPE_WEIGHT,
        gender: GENDER_FEMALE
    }
}

/**
 * Заполнение продолжительности жизни самцов
 */
function fillMaleAge(healthDto, data) {
    if (data[MALE_LIFE_FROM] === 0 || data[MALE_LIFE_TO] === 0) {
        return;
    }
    healthDto.maleAge = {
        id: data[MALE_LIFE_RANGE_ID],
        from: data[MALE_LIFE_FROM] === 0 ? null : data[MALE_LIFE_FROM],
        to: data[MALE_LIFE_TO] === 0 ? null : data[MALE_LIFE_TO],
        measure: MEASURE_TYPE_YEAR,
        rangeType: RANGE_TYPE_AGE,
        gender: GENDER_MALE
    }
}

/**
 * Заполнение продолжительности жизни самок
 */
function fillFemaleAge(healthDto, data) {
    if (data[FEMALE_LIFE_FROM] === 0 || data[FEMALE_LIFE_TO] === 0) {
        return;
    }
    healthDto.femaleAge = {
        id: data[FEMALE_LIFE_RANGE_ID],
        from: data[FEMALE_LIFE_FROM] === 0 ? null : data[FEMALE_LIFE_FROM],
        to: data[FEMALE_LIFE_TO] === 0 ? null : data[FEMALE_LIFE_TO],
        measure: MEASURE_TYPE_YEAR,
        rangeType: RANGE_TYPE_AGE,
        gender: GENDER_FEMALE
    }
}


/**
 * Создает скелет объекта для заимствованной информации
 */
export const addSourceLink = async (sourceIds, sourceLinks, setSourceLinks) => {
    setSourceLinks([...sourceLinks,
        {
            id: sourceIds.current,
            borrowedObject: "",
            sourceLink: "",
            linkDescription: ""
        }])
    sourceIds.current = sourceIds.current + 1;
}


/**
 * Инициализация заимствованной информации данными, пришедшими с бэка
 */
export const initSourceLinks = (sourceIds, setSourceLinks, data) => {
    if (data === null || data === undefined || data.length === 0) {
        return;
    }

    setSourceLinks(data)
    sourceIds.current = getEntityNextId(data);
}

export function initCheckBoxes(checkboxes) {
    if (isEmptyOrNull(checkboxes)) {
        return;
    }
    checkboxes.map(checkbox => storeItem(checkbox.fieldName, checkbox.value))
}

/**
 * Создает скелет объекта объект Фактов
 */
export const addFact = async (factsIds, breedFacts, setBreedFacts) => {
    setBreedFacts([...breedFacts,
        {
            id: factsIds.current,
            fact: EMPTY
        }])
    factsIds.current = factsIds.current + 1;
}

/**
 * Инициализация фактов данными, пришедшими с бэка
 */
export const initFacts = (factsIds, setBreedFacts, data = []) => {
    if (isEmptyOrNull(data)) {
        return;
    }

    setBreedFacts(data)
    factsIds.current = getEntityNextId(data);
}

//получить следующий идентификатор для элемента - массива, сохраняемой в sessionStorage
export function getEntityNextId(entity) {
    if (entity.length === 0) {
        return 0;
    }
    let lastId = Math.max.apply(Math, entity.map(function (o) {
        return o.id;
    }))
    return lastId + 1;
}

/**
 * Факты
 */
export function getStoredFacts(setValue) {
    const storedFacts = JSON.parse(sessionStorage.getItem(BREED_FACTS));
    if (storedFacts === null || storedFacts.length === 0) {
        return [];
    }
    setValue(BREED_FACTS, storedFacts)
    return storedFacts;
}

//удалить поля формы, зачистить соответствующую запись в sessionStorage и пересетить
//оставшиеся поля в таблице
export function removeFact(index, getValues, setValue, setBreedFacts, toStore) {
    //получаем значение с формы
    const {breedFacts} = getValues();
    //фильтруем - нам нужно удалить элемент с порядковым номером
    let newFacts = [...breedFacts].filter((el, idx) => idx !== Number(index))
    setBreedFacts(newFacts);
    if (toStore) {
        sessionStorage.setItem(BREED_FACTS, JSON.stringify(newFacts))
    }
    setValue(BREED_FACTS, newFacts)
}

/**
 * Источник информации
 */

//получить сохраненные в sessionStorage записи
//вернуть их и также просетить в setValue хука useForm
export function getStoredSources(setValue) {
    const storedSources = JSON.parse(sessionStorage.getItem(SOURCES));
    if (storedSources === null || storedSources.length === 0) {
        return [];
    }
    setValue(SOURCES, storedSources)
    return storedSources;
}

//удалить поля формы, зачистить соответствующую запись в sessionStorage и пересетить
//оставшиеся поля в таблице
export function removeSourceLink(index, getValues, setValue, setSourceLinks, toStore) {
    //получаем значение с формы
    const {sources} = getValues();
    //фильтруем - нам нужно удалить элемент с порядковым номером
    let newLinks = [...sources].filter((el, idx) => idx !== Number(index))
    setSourceLinks(newLinks);
    if (toStore) {
        sessionStorage.setItem(SOURCES, JSON.stringify(newLinks))
    }
    setValue(SOURCES, newLinks)
}

/**
 * Получить данные о породе по id
 *
 * @param breedId id породы
 * @param setBreed сеттер породы
 * @param setErrorMessage сеттер сообщения об ошибке
 */
export function getBreedInfo(breedId, setBreed, setErrorMessage) {
    if (breedId === null || breedId === undefined || breedId === 0) {
        return;
    }
    const response = BreedService.getBreedDataById(breedId);

    response.then((resp) => {
        const data = resp.data.data;
        setBreed(data)
    }, (error) => {
        setErrorMessage(error.response?.data?.messages ?
            error.response?.data?.messages?.ERROR[0] : error.message)
    })
}

/**
 * Получить все значения Enum'ов для всех разделов породы
 */
export function getEnumsValues(setErrorMessage, setBreedEnums, token) {
    const response = getDictionaryEnums(BREED_METADATA.backControllerName, token);
    response.then((resp) => {
        setErrorMessage(EMPTY)
        setBreedEnums(resp.data.data)
    }, (error) => {
        setErrorMessage(error.response?.data?.messages ?
            error.response?.data?.messages?.ERROR[0] : error.message)
    });
}

/**
 * Получить все значения Enum'ов для всех разделов породы
 */
export function getAnimalClubsValues(setErrorMessage, setAnimalClubs) {
    const response = getAllRecordsInCurrentTranslation(ANIMAL_CLUBS_METADATA.backControllerName);
    response.then((resp) => {
        setErrorMessage(EMPTY)
        setAnimalClubs(resp.data.data)
    }, (error) => {
        setErrorMessage(error.response?.data?.messages ?
            error.response?.data?.messages?.ERROR[0] : error.message)
    });
}

/**
 * Формирование гендерно-зависимых параметров для удобного отображения
 * @param breedInfo инфо о породе
 * @param setAllGendersParams сеттер для управляемой сущности гендерно-зависимых параметров
 */
export function formGenderParams(breedInfo, setAllGendersParams) {
    if (isEmptyOrNull(breedInfo)) {
        setAllGendersParams({male: [], female: []})
        return;
    }
    const maleParams = [
        breedInfo.appearance?.maleWeight,
        breedInfo.appearance?.maleSize,
        breedInfo.health?.maleAge
    ];

    const femaleParams = [
        breedInfo.appearance?.femaleWeight,
        breedInfo.appearance?.femaleSize,
        breedInfo.health?.femaleAge
    ];

    setAllGendersParams({
        male: fillGenderRanges(maleParams),
        female: fillGenderRanges(femaleParams)
    })
}

function fillGenderRanges(params) {
    const result = [];
    if (isEmptyOrNull(params)) {
        return result;
    }

    params.forEach(param => {
        if (!isEmptyOrNull(param) && !isEmptyOrNull(param.from) && !isEmptyOrNull(param.to)) {
            result.push({
                title: param.rangeType,
                data: {from: param.from, to: param.to, measure: param.measure}
            })
        }
    });
    return result;
}
