import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    token: '',
    refreshToken: '',
    photo: '',
    country: null,
    region: null,
    city: null,
    isUserAuth: false,
    isModerator: false,
    isAdmin: false,
    activePet: null,
    pets: [],
};

const userSlice = createSlice({
    name: 'userSlice',
    initialState: initialState,
    reducers: {
        setFirstName(state, action) {
            state.firstName = action.payload;
        },
        setLastName(state, action) {
            state.lastName = action.payload;
        },
        setEmail(state, action) {
            state.email = action.payload;
        },
        setUserPhoto(state, action) {
            state.photo = action.payload.roundPhoto;
        },
        setUserToken(state, action) {
            state.token = action.payload
        },
        setRefreshToken(state, action) {
            state.refreshToken = action.payload
        },
        setUserAuth(state) {
            state.isUserAuth = true;
        },
        setModerator(state) {
            state.isModerator = true;
        },
        setAdmin(state) {
            state.isAdmin = true;
        },
        clearRegData(state) {
            state.firstName = '';
            state.lastName = '';
            state.email = '';
        },
        setUserPets(state, action) {
            state.pets = action.payload.pets;
        },
        setUserLocation(state, action) {
            state.country = action.payload.country;
            state.region = action.payload.region;
            state.city = action.payload.city;
        },
        setActivePet(state, action) {
            state.activePet = action.payload.activePet;
        },
        updatePetName(state, action) {
            state.activePet.name = action.payload.name;
            state.pets.map(pet => pet.id === action.payload.petId ? pet.name = action.payload.name : pet);
        },
        updatePetBreed(state, action) {
            state.activePet.breedId = action.payload.breedId;
        },
        updatePetIdentificationInfo(state, action) {
            state.activePet.identificationInfo = action.payload.identificationInfo;
        },
        updatePetGender(state, action) {
            state.activePet.petGender = action.payload.petGender;
        },
        updatePetLivingEnvironment(state, action) {
            state.activePet.livingEnvironment = action.payload.livingEnvironment;
        },
        updatePetLivingConditions(state, action) {
            state.activePet.livingConditions = action.payload.livingConditions;
        },
        updatePetBirthDate(state, action) {
            state.activePet.birthDate = action.payload.birthDate;
        },
        setPetLogo(state, action) {
            state.pets.map(pet => pet.id === action.payload.petId ? pet.logo = action.payload.logo : pet);
        },
        setActivePetPhoto(state, action) {
            if (state.activePet.id === action.payload.petId) {
                state.activePet.photo = action.payload.roundPhoto;
            }
        },
        loginUser(state, action) {
            state.id = action.payload.id;
            state.isUserAuth = true;
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.email = action.payload.email;
            state.photo = action.payload.photo;
            state.token = action.payload.token;
            state.refreshToken = action.payload.refreshToken;
            state.isAdmin = action.payload.isAdmin;
            state.isModerator = action.payload.isModerator;
            state.activePet = action.payload.activePet;
            state.country = action.payload.country;
            state.region = action.payload.region;
            state.city = action.payload.city;
            state.pets = action.payload.pets;
        },
        logoutUser(state) {
            state.id = '';
            state.firstName = '';
            state.lastName = '';
            state.email = '';
            state.photo = '';
            state.token = '';
            state.refreshToken = '';
            state.isUserAuth = false;
            state.isModerator = false;
            state.isAdmin = false;
            state.activePet = null;
            state.country = null;
            state.region = null;
            state.city = null;
            state.pets = [];
        }
    }
})

export default userSlice.reducer;
export const {
    setAdmin,
    setFirstName,
    setLastName,
    setEmail,
    setUserPhoto,
    setUserToken,
    setRefreshToken,
    setUserAuth,
    clearRegData,
    loginUser,
    logoutUser,
    setModerator,
    setActivePet,
    setUserLocation,
    setUserPets,
    setPetLogo,
    setActivePetPhoto,
    updatePetName,
    updatePetBreed,
    updatePetGender,
    updatePetLivingEnvironment,
    updatePetLivingConditions,
    updatePetBirthDate,
    updatePetIdentificationInfo
} = userSlice.actions
