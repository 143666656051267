import axios from "axios";
import {LOCAL} from "../app/const/main";

/**
 * Получить список всех значений справочника в текущем переводе
 */
export async function getAllRecordsInCurrentTranslation(dictionaryNameInController) {
    return await axios.get(`${LOCAL}/${dictionaryNameInController}`);
}

/**
 * Получить сведения о записи справочника по Id на текущем языке
 */
export async function getAllRecordDataById(id, dictionaryNameInController, token) {
    //получение элемента справочника не требует проверки авторизации
    const config = {
        headers: {
            'Authorization': 'Bearer_' + token
        }
    }
    return await axios.get(`${LOCAL}/${dictionaryNameInController}/${id}`, config);
}

/**
 * Получить возможные значения (для списка enum'ов или справочников)
 */
export async function getPossibleValues(pathToPossibleValues, token) {
    //получение всех возможных значений вспомогательных элементов для справочника не требует проверки авторизации
    //TODO: подумать, возможно следует вернуть проверку авторизации

    const config = {
        headers: {
            'Authorization': 'Bearer_' + token
        }
    }
    return await axios.get(`${LOCAL}/${pathToPossibleValues}`, config);
}

/**
 * Получить список регионов, относящихся к определенной стране
 */
export async function getRegionsByCountryCode(regionsBackPath, countryCode) {
    const config = {
        params: {countryCode: countryCode}
    }
    return await axios.get(`${LOCAL}/${regionsBackPath}/by-country-code`, config);
}

/**
 * Получить список городов, относящихся к определенному региону
 */
export async function getCitiesByRegionCode(citiesBackPath, regionCode) {
    const config = {
        params: {regionCode: regionCode}
    }
    return await axios.get(`${LOCAL}/${citiesBackPath}/by-region-code`, config);
}

/**
 *  Сохранить/обновить сведения о записи в справочнике на конкретном языке
 */
export async function saveRecord(data, dictionaryNameInController, token) {
    const form = new FormData();
    const dtoBlob = new Blob([JSON.stringify(data)], {
        type: 'application/json'
    });

    form.append("dto", dtoBlob)
    return await axios.post(`${LOCAL}/${dictionaryNameInController}/save`, form,
        {
            headers: {
                'Authorization': 'Bearer_' + token
            }
        });
}

/**
 * Удалить запись из справочника по Id
 * (где ID - идентификатор мультиязычной сущности, т.е. удалятся все переводы)
 */
export async function removeRecord(id, dictionaryNameInController, token) {
    const config = {
        headers: {
            'Authorization': 'Bearer_' + token
        }
    }
    return await axios.delete(`${LOCAL}/${dictionaryNameInController}/${id}/delete`, config);
}


/**
 * Получить все значения Enum'ов
 * для дальнейшего размещения их в списке radio button'ов
 */
export async function getDictionaryEnums(dictionaryNameInController, token) {
    const config = {
        headers: {
            'Authorization': 'Bearer_' + token
        }
    }
    return await axios.get(`${LOCAL}/${dictionaryNameInController}/enums`, config);
}