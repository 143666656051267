import React from 'react';
import ButtonGroup from "../../../components/common/ui/buttons/ButtonGroup";
import {useNavigate} from "react-router-dom";
import OrangeButtonFilled from "../../../components/common/ui/buttons/OrangeButtonFilled";
import SuccessMessageByCenter from "../../../components/common/ui/SuccessMessageByCenter";

/**
 * Сообщение об успешной смене пароля
 */
const ResetPasswordSuccessMessage = ({successMessage}) => {
    const navigate = useNavigate();

    return (
        <>
            <SuccessMessageByCenter message={successMessage}/>
            <ButtonGroup>
                <OrangeButtonFilled onClick={() => navigate("/login")}>
                    Войти
                </OrangeButtonFilled>
            </ButtonGroup>
        </>
    );
};

export default ResetPasswordSuccessMessage;