import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {EMPTY} from "../../../app/const/appConst";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import {USER_CITY, USER_COUNTRY, USER_REGION} from "../../../components/common/form/helper/commonFormConstants";
import UpdatableElementPreview from "../../../components/common/UpdatableElementPreview";
import Form from "../../../components/common/form/abstract/Form";
import FormSubmitButton from "../../../components/common/form/parts/inputs/FormSubmitButton";
import PartInfoContent from "../../../components/common/form/abstract/PartInfoContent";
import {hasValue, isEmptyOrNull} from "../../../app/helper/commonHelper";
import UserLocationFormPart from "../../../components/common/form/parts/user/UserLocationFormPart";
import {ACTION_UPDATE} from "../../../components/common/form/helper/formConstants";
import UserService from "../../../service/UserService";
import {setUserLocation} from "../../../app/store/userReducer";

const UserLocation = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const userCountry = useSelector(state => state.userReducer.country);
    const userRegion = useSelector(state => state.userReducer.region);
    const userCity = useSelector(state => state.userReducer.city);
    const token = useSelector(state => state.userReducer.token);
    const [errorMessage, setErrorMessage] = useState(EMPTY);
    const [isLoading, setIsLoading] = useState(false);

    const schema = yup.object().shape({
        userCountry: yup.string().required(t("form.common.errors.fieldRequired")),
        userRegion: yup.string(),
        userCity: yup.string().when('userRegion', {
            is: (val) => val && val.length > 0,
            then: yup.string().required(t("form.common.errors.fieldRequired")),
            otherwise: yup.string()
        }),
    })

    const {
        register,
        formState: {errors},
        handleSubmit,
        setValue,
        getValues,
        watch
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onBlur",
        reValidateMode: "onBlur"
    })

    function clearErrors() {
        setErrorMessage(EMPTY);
    }

    function init() {
        setValue(USER_COUNTRY, hasValue(userCountry) ? userCountry.code : EMPTY);
        setValue(USER_REGION, hasValue(userRegion) ? userRegion.code : EMPTY);
        setValue(USER_CITY, hasValue(userCity) ? userCity.code : EMPTY);
    }

    function isButtonActive() {
        let isActive =  hasValue(userCountry) ? userCountry.code !== watch(USER_COUNTRY) : true;
        return isActive === true ? true : ((isEmptyOrNull(watch(USER_CITY))) && isEmptyOrNull(userCity?.code)) ?
            false : watch(USER_CITY) !== userCity?.code   ;
    }

    function submitForm(data) {
        if (!isButtonActive()) {
            return;
        }
        clearErrors();
        setIsLoading(true)
        const dto = formDto(data)
        const response = UserService.updateLocation(dto, token);
        response.then((resp) => {
            dispatch(setUserLocation(resp.data.data))
        }, (error) => {
            setErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message)
        });
        setIsLoading(false)
    }

    function formDto(data) {
        const locationDto = {}
        if (isEmptyOrNull(data)) {
            return {}
        }
        if (hasValue(data[USER_COUNTRY])) {
            locationDto.country={code: data[USER_COUNTRY]}
        }
        if (hasValue(data[USER_REGION])) {
            locationDto.region={code: data[USER_REGION]}
        }
        if (hasValue(data[USER_CITY])) {
            locationDto.city={code: data[USER_CITY]}
        }
        return locationDto;
    }

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
        clearErrors();
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <PartInfoContent errorMessage={errorMessage}>
            <UpdatableElementPreview title='Локация' backTo='/profile'
                                     content={t('page.profile.canEditLocation')}/>
            <Form handleSubmit={handleSubmit} submitForm={submitForm}>
                <UserLocationFormPart errors={errors} setErrorMessage={setErrorMessage} setValue={setValue}
                                      getValues={getValues} register={register} action={ACTION_UPDATE}/>
                <FormSubmitButton title={t('button.save')} isLoading={isLoading} isButtonActive={isButtonActive}
                                  handleSubmit={handleSubmit} submitForm={submitForm}/>
            </Form>
        </PartInfoContent>
    );
};

export default UserLocation;