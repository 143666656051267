import React from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {hasValue} from "../../../../app/helper/commonHelper";
import {
    ACTION_ADD,
    FORM_CHECKBOX_SET,
    FORM_INPUT,
    FORM_NUMBER_INPUT,
    FORM_RADIO_SET,
    FORM_SINGLE_OPTION,
    FORM_TEXTAREA,
    HIDDEN
} from "../../form/helper/formConstants";
import {useSelector} from "react-redux";
import Content from "../../Content";
import TableNoResults from "../../table/TableNoResults";
import HiddenInput from "../../form/parts/inputs/HiddenInput";
import TextInputFormItem from "../../form/parts/inputs/wrappers/TextInputFormItem";
import {isFieldFilled} from "../../form/helper/formHelper";
import SubmitSection from "../../form/parts/controlsection/SubmitSection";
import Form from "../../form/abstract/Form";
import TextAreaFormItem from "../../form/parts/inputs/wrappers/TextAreaFormItem";
import NumberInputFormItem from "../../form/parts/inputs/wrappers/NumberInputFormItem";
import RadioSetFormItem from "../../form/parts/inputs/wrappers/RadioSetFormItem";
import CheckboxSetFormItem from "../../form/parts/inputs/wrappers/CheckboxSetFormItem";
import DictionaryHeader from "../DictionaryHeader";
import useDictionaryTranslations from "./useDictionaryTranslations";
import TextInputSingleOptionFormItem from "../../form/parts/inputs/wrappers/TextInputSingleOptionFormItem";

/**
 * Страница для добавления/редактирования элемента справочника
 * @param metadata - описание справочника
 * @param dictionaryEditingItem - редактируемый элемент справочника, м.б. null (при добавлении нового элемента)
 */
const DictionaryTranslations = ({metadata}) => {
        const params = useParams();
        const navigate = useNavigate();
        const {t, i18n} = useTranslation();
        const location = useLocation();
        const token = useSelector(state => state.userReducer.token);

        const {
            action,
            possibleValues,
            error,
            errors,
            getValues,
            setValue,
            register,
            getOnBlurFunction,
            errorMessage,
            pageTitle,
            handleSubmit,
            submitForm,
            submitErrorMessage,
            loading,
            disabledSubmit
        } = useDictionaryTranslations(metadata, t, i18n, params, token, navigate, location);

        if (hasValue(errorMessage)) {
            return (<Content>
                <TableNoResults title={errorMessage}/>
            </Content>);
        }

        /**
         * Форма строится из полей metadat'ы справочника для которых определено значение formElement и order
         */
        return (
            <Content>
                <DictionaryHeader mainInscription={`${t(metadata.dictionaryTitle)}${pageTitle}`}/>
                <Form handleSubmit={handleSubmit} submitForm={submitForm}>
                    {Object.entries(metadata.fields)
                        .filter(([, value]) => value.hasOwnProperty('formElement') && value.hasOwnProperty('order'))
                        .sort(([, a], [, b]) => a.order - b.order)
                        .map((field, index) => {
                            const fieldName = field[0];
                            switch (field[1].formElement) {
                                case HIDDEN:
                                    return (<HiddenInput key={index} fieldName={field[1].title} register={register}/>);
                                case FORM_INPUT:
                                    return (<TextInputFormItem key={index} errors={errors} fieldName={field[1].title}
                                                               placeholder={t(field[1].placeholder)}
                                                               register={register}
                                                               hasValue={isFieldFilled(getValues, field[1].title)}
                                                               tabIndex={field.order}
                                                               onBlurFunction={getOnBlurFunction()}/>);
                                case FORM_NUMBER_INPUT:
                                    return <NumberInputFormItem key={index} fieldName={field[1].title} register={register}
                                                                placeholder={t(field[1].placeholder)}
                                                                onBlurFunction={getOnBlurFunction()}
                                                                tabIndex={field.order}
                                                                hasValue={isFieldFilled(getValues, field[1].title)}
                                                                errors={errors}/>;
                                case FORM_TEXTAREA:
                                    return (<TextAreaFormItem key={index} fieldName={field[1].title}
                                                              placeholder={t(field[1].placeholder)}
                                                              register={register} tabIndex={field[1].order}
                                                              action={action} errors={errors}
                                                              hasValue={isFieldFilled(getValues, field[1].title)}/>);
                                case FORM_RADIO_SET:
                                    return <RadioSetFormItem key={index} register={register} fieldName={field[1].title}
                                                             radioSetTitle={t(field[1].placeholder)}
                                                             options={possibleValues[fieldName]}
                                                             required={false}
                                                             needToStore={action === ACTION_ADD} errors={errors}/>;
                                case FORM_CHECKBOX_SET:
                                    return <CheckboxSetFormItem key={index} options={possibleValues[fieldName]}
                                                                register={register} errors={errors}
                                                                field={field[1]}
                                                                getValues={getValues} setValue={setValue}
                                                                action={action}
                                                                headerTitle={t(field[1].placeholder)}/>
                                case FORM_SINGLE_OPTION:
                                    return <TextInputSingleOptionFormItem key={index} action={action}
                                                                          fieldName={field[1].title}
                                                                          fieldOrder={field[1].order}
                                                                          options={possibleValues[fieldName]}
                                                                          placeholder={t(field[1].placeholder)}
                                                                          register={register}
                                                                          isNeedToStore={ACTION_ADD === action}
                                                                          setValue={setValue} getValues={getValues}/>;
                                default:
                                    return <></>;
                            }
                        })
                    }
                    <SubmitSection hasFormError={error} errorMessage={submitErrorMessage} isFormLoading={loading}
                                   submitLabel={t("button.save")} isSubmitDisabled={disabledSubmit}/>
                </Form>
            </Content>
        );
    }
;

export default DictionaryTranslations;