import {ACTION_UPDATE, BOOLEAN, ENUM, FORM_MULTI_OPTIONS, FORM_SINGLE_OPTION, NUMBER, STRING} from "./formConstants";
import DoneImage from "../../ui/text/DoneImage";
import SuccessActionMessage from "../../SuccessActionMessage";
import React from "react";
import {hasValue, isEmptyOrNull} from "../../../../app/helper/commonHelper";
import {EMPTY} from "../../../../app/const/appConst";
import {BREED_FACTS, SOURCES} from "../../../../pages/breeds/helpers/breedFormConstants";

/**
 * Функция сохраняет элемент массива в sessionStorage
 * @param idx идентификатор записи в sessionStorage
 * @param objectName key в sessionStorage
 * @param fieldName поле в массиве, которое сохраняется/апдейтится
 * @param value значение
 */
export function storeArrayElement(idx, objectName, fieldName, value) {
    let storedArray = JSON.parse(sessionStorage.getItem(objectName));
    //если sessionStorage c таким ключом не найден или нет такого элемента в коллекции
    if (storedArray == null) {
        sessionStorage.setItem(objectName, JSON.stringify([{id: idx, [fieldName]: value}]))
    } else {
        // если новая запись
        if (storedArray.filter(el => el.id === idx).length === 0) {
            storedArray.push({id: idx, [fieldName]: value});
            sessionStorage.setItem(objectName, JSON.stringify(storedArray))
        } else {
            //обновляем поля
            let updateId = storedArray.findIndex(el => el.id === idx);
            storedArray[updateId] = {...storedArray[updateId], [fieldName]: value};
            sessionStorage.setItem(objectName, JSON.stringify(storedArray))
        }
    }
}

/**
 * Поместить значение элемента в SessionStorage
 */
export function storeItem(fieldName, value) {
    sessionStorage.setItem(fieldName, value)

}

/**
 * Удалить данные поля из sessionStorage
 */
export function removeItem(fieldName) {
    sessionStorage.removeItem(fieldName)
}

/**
 * Очистить sessionStorage от данных по стране
 */
export function clearSessionDataForEntity(entityFields, action) {
    // при обновлении сущности формы сохранение значений полей в стор не происходит
    if (ACTION_UPDATE === action) {
        return;
    }
    entityFields.forEach(field => sessionStorage.removeItem(field.title));
    sessionStorage.removeItem(BREED_FACTS)
    sessionStorage.removeItem(SOURCES)
}

/**
 * Очистить sessionStorage от данных справочника
 */
export function clearSessionDataForDictionary(dictionaryFields, action) {
    // при обновлении сущности формы сохранение значений полей в стор не происходит
    if (ACTION_UPDATE === action) {
        return;
    }
    Object.values(dictionaryFields).forEach(field => sessionStorage.removeItem(field.title))
}

/**
 * Получить значение элемента из SessionStorage или вернуть значение по умолчанию
 */
export function getStoredItem(name, type) {
    const storedItem = sessionStorage.getItem(name);
    if (storedItem === null) {
        return getDefaultValueForType(type);
    }
    if (FORM_SINGLE_OPTION === type || FORM_MULTI_OPTIONS === type) {
        return JSON.parse(storedItem);
    }
    return storedItem;
}

/**
 * Значение по умолчанию для каждого типа поля
 */
export function getDefaultValueForType(type) {
    switch (type) {
        case STRING:
            return EMPTY;
        case NUMBER:
            return 0;
        case BOOLEAN:
            return false;
        case ENUM:
            return {id: EMPTY, name: EMPTY};
        default:
            return EMPTY;
    }
}

/**
 * Функция для преобразования строки в булево значение
 */
export function stringToBoolean(str) {
    if (isEmptyOrNull(str)) {
        return false;
    }
    if (typeof str === 'boolean') {
        return str;
    }
    return str.toLowerCase() === 'true';
}

export function hasStoredItem(item) {
    return sessionStorage.getItem(item) !== null;
}

/**
 * Вернет true, если значение в сторе отсутствует или пустое
 */
export function isEmptyStoreItem(name, type) {
    const val = getStoredItem(name, type);
    switch (type) {
        case STRING:
            return val === '';
        case NUMBER:
            return val === 0;
        default:
            return val === '';
    }
}

/**
 * Логика, которую надо выполнить при потере фокуса полем
 */
export function makeBlurLogic(value, setLabelClass, fieldName = null, storeFunction = null,
                              dispatch = null, dispatchFunction = null) {
    if (value === '' && setLabelClass !== null && setLabelClass !== undefined) {
        movePlaceholderDown(setLabelClass)
    }
    //сохранение в стор
    if (storeFunction !== null && storeFunction !== undefined && fieldName !== null) {
        storeFunction(fieldName, value)
    }
    //сохранение в редьюсер
    if (dispatchFunction !== null && dispatchFunction !== undefined && dispatch !== null) {
        dispatch(dispatchFunction(value))
    }
}

/**
 * Добавляет класс к лейблу инпута, чтобы он уехал наверх при фокусе на поле
 */
export function movePlaceholderUp(setLabelClass) {
    return setLabelClass('focused form-item__label');
}

/**
 * Опускает лейбл инпута в поле
 */
export function movePlaceholderDown(setLabelClass) {
    return setLabelClass('form-item__label');
}

/**
 * Открывает модальное окно для успешно выполненного действия с кастомным сообщением
 */
export function showSuccessMessagePopup(dispatch, showModal, header, message) {
    dispatch(showModal({
        popup: {
            title: <DoneImage/>,
            body: <SuccessActionMessage header={header} message={message}/>
        }
    }))
}

/**
 * Метод, который определяет заполнено ли поле
 *
 * @param getValues функция из хука useForm
 * @param fieldName имя поля
 */
export function isFieldFilled(getValues, fieldName) {
    const fieldValue = getValues(fieldName);
    return hasValue(fieldValue);
}