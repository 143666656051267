import {
    BOOLEAN,
    DICTIONARY_SET,
    ENUM_SET,
    FORM_SINGLE_OPTION,
    NUMBER,
    STRING
} from "../../../components/common/form/helper/formConstants";

/**
 * Поля формы породы
 */
export const BREED_ID = "breedId";
export const BREED_CODE = "breedCode";
export const BREED_TRANSLATION_ID = "translationId";
export const BREED_HISTORICAL_PERIOD = "breedHistoricalPeriod";
export const BREED_GLOBAL_PRESENCE = "breedGlobalPresence";
export const BREEDING_PURPOSE = "breedingPurpose";
export const BREED_ANIMAL_CLUB = "breedAnimalClub";
export const BREED_FIRST_MENTION = "breedFirstMention";
export const BREED_CULTURAL_SIGNIFICANCE = "breedСulturalSignificance";
export const BREED_TITLE = "title";
export const BREED_APPEARANCE_ID = "appearanceId";
export const HEALTH_ID = "healthId";
export const BREED_FACTS = "breedFacts";
export const SOURCES = "sources";
export const BREED_HISTORY = "breedHistory";
//MALE
//size
export const MALE_SIZE_RANGE_ID = "maleSizeRangeId";
export const MALE_SIZE_FROM = "maleSizeFrom";
export const MALE_SIZE_TO = "maleSizeTo";
//weight
export const MALE_WEIGHT_RANGE_ID = "maleWeightRangeId";
export const MALE_WEIGHT_FROM = "maleWeightFrom";
export const MALE_WEIGHT_TO = "maleWeightTo";
//age
export const MALE_LIFE_RANGE_ID = "maleLifeRangeId";
export const MALE_LIFE_FROM = "maleLifeFrom";
export const MALE_LIFE_TO = "maleLifeTo";
//FEMALE
//size
export const FEMALE_SIZE_RANGE_ID = "femaleSizeRangeId";
export const FEMALE_SIZE_FROM = "femaleSizeFrom";
export const FEMALE_SIZE_TO = "femaleSizeTo";
//weight
export const FEMALE_WEIGHT_RANGE_ID = "femaleWeightRangeId";
export const FEMALE_WEIGHT_FROM = "femaleWeightFrom";
export const FEMALE_WEIGHT_TO = "femaleWeightTo";
//age
export const FEMALE_LIFE_RANGE_ID = "femaleLifeRangeId";
export const FEMALE_LIFE_FROM = "femaleLifeFrom";
export const FEMALE_LIFE_TO = "femaleLifeTo";
export const BREED_CARE_ID = "breedCareId";
export const CARE_DESCRIPTION = "care";
export const CARE_LEVEL = "careLevel";
export const CARE_GROOMING = "careGrooming";
export const CARE_BATHING = "careBathing";
export const CARE_EAR_CLEANING = "careEarCleaning";
export const CARE_EYE_CLEANING = "careEyeCleaning";
export const CARE_TEETH_CLEANING = "careTeethCleaning";

export const BEHAVIOUR = "behaviour";

export const BORROWED_OBJECT = 'borrowedObject'
export const FACT = 'fact'
export const SOURCE_LINK = 'sourceLink'
export const LINK_DESCRIPTION = 'linkDescription'
export const BREED_COUNTRY_ID = 'breedCountry'
export const BREED_DISEASES = 'breedDiseases'
export const BREED_IS_TECHNICAL = 'breedIsTechnical'

export const BREED_METADATA = {
    title: 'page.breed.title',
    backControllerName: 'breeds',
    titlePrepositionalCase: 'page.breed.titlePrepositionalCase',
    fields: {
        id: {title: BREED_ID, objectPath: 'id', type: NUMBER},
        code: {title: BREED_CODE, objectPath: 'code', type: STRING, tabIndex: 2},
        appearanceId: {title: BREED_APPEARANCE_ID, objectPath: 'appearance.id', type: NUMBER},
        maleSizeRangeId: {title: MALE_SIZE_RANGE_ID, objectPath: 'appearance.maleSize.id', type: NUMBER},
        maleSizeFrom: {title: MALE_SIZE_FROM, objectPath: 'appearance.maleSize.from', type: NUMBER, tabIndex: 5},
        maleSizeTo: {title: MALE_SIZE_TO, objectPath: 'appearance.maleSize.to', type: NUMBER, tabIndex: 6},
        maleWeightRangeId: {title: MALE_WEIGHT_RANGE_ID, objectPath: 'appearance.maleWeight.id', type: NUMBER},
        maleWeightFrom: {title: MALE_WEIGHT_FROM, objectPath: 'appearance.maleWeight.from', type: NUMBER, tabIndex: 7},
        maleWeightTo: {title: MALE_WEIGHT_TO, objectPath: 'appearance.maleWeight.to', type: NUMBER, tabIndex: 8},
        femaleSizeRangeId: {title: FEMALE_SIZE_RANGE_ID, objectPath: 'appearance.femaleSize.id', type: NUMBER},
        femaleSizeFrom: {title: FEMALE_SIZE_FROM, objectPath: 'appearance.femaleSize.from', type: NUMBER, tabIndex: 11},
        femaleSizeTo: {title: FEMALE_SIZE_TO, objectPath: 'appearance.femaleSize.to', type: NUMBER, tabIndex: 12},
        femaleWeightRangeId: {title: FEMALE_WEIGHT_RANGE_ID, objectPath: 'appearance.femaleWeight.id', type: NUMBER},
        femaleWeightFrom: {
            title: FEMALE_WEIGHT_FROM,
            objectPath: 'appearance.femaleWeight.from',
            type: NUMBER,
            tabIndex: 13
        },
        femaleWeightTo: {title: FEMALE_WEIGHT_TO, objectPath: 'appearance.femaleWeight.to', type: NUMBER, tabIndex: 14},
        healthId: {title: HEALTH_ID, objectPath: 'health.id', type: NUMBER},
        maleLifeRangeId: {title: MALE_LIFE_RANGE_ID, objectPath: 'health.maleAge.id', type: NUMBER},
        maleLifeFrom: {title: MALE_LIFE_FROM, objectPath: 'health.maleAge.from', type: NUMBER, tabIndex: 9},
        maleLifeTo: {title: MALE_LIFE_TO, objectPath: 'health.maleAge.to', type: NUMBER, tabIndex: 10},
        femaleLifeRangeId: {title: FEMALE_LIFE_RANGE_ID, objectPath: 'health.femaleAge.id', type: NUMBER},
        femaleLifeFrom: {title: FEMALE_LIFE_FROM, objectPath: 'health.femaleAge.from', type: NUMBER, tabIndex: 15},
        femaleLifeTo: {title: FEMALE_LIFE_TO, objectPath: 'health.femaleAge.to', type: NUMBER, tabIndex: 16},
        careId: {title: BREED_CARE_ID, objectPath: 'breedCare.id', type: NUMBER},
        careDescription: {title: CARE_DESCRIPTION, objectPath: 'translation.care', type: STRING, tabIndex: 17},
        careLevel: {title: CARE_LEVEL, objectPath: 'breedCare.careLevel.id', type: STRING},
        careGrooming: {title: CARE_GROOMING, objectPath: 'breedCare.groomingFrequency.id', type: STRING},
        careBathing: {title: CARE_BATHING, objectPath: 'breedCare.bathingFrequency.id', type: STRING},
        careEarCleaning: {title: CARE_EAR_CLEANING, objectPath: 'breedCare.earCleaningFrequency.id', type: STRING},
        careEyeCleaning: {title: CARE_EYE_CLEANING, objectPath: 'breedCare.eyeCleaningFrequency.id', type: STRING},
        careTeethCleaning: {
            title: CARE_TEETH_CLEANING,
            objectPath: 'breedCare.teethCleaningFrequency.id',
            type: STRING
        },
        country: {title: BREED_COUNTRY_ID, objectPath: 'country', type: FORM_SINGLE_OPTION, tabIndex: 3},
        diseases: {title: BREED_DISEASES, objectPath: 'diseases', type: DICTIONARY_SET, tabIndex: 66},
        translationId: {title: BREED_TRANSLATION_ID, objectPath: 'translation.id', type: NUMBER},
        historicalPeriod: {title: BREED_HISTORICAL_PERIOD, objectPath: 'historicalPeriod.id', type: STRING},
        breedingPurposes: {title: BREEDING_PURPOSE, objectPath: 'breedingPurposes', type: ENUM_SET},
        animalClubs: {title: BREED_ANIMAL_CLUB, objectPath: 'clubs', type: DICTIONARY_SET},
        globalPresence: {title: BREED_GLOBAL_PRESENCE, objectPath: 'globalPresence.id', type: STRING},
        firstMention: {title: BREED_FIRST_MENTION, objectPath: 'translation.firstMention', type: STRING, tabIndex: 100},
        culturalSignificance: {
            title: BREED_CULTURAL_SIGNIFICANCE,
            objectPath: 'translation.culturalSignificance',
            type: STRING,
            tabIndex: 101
        },
        breedTitle: {title: BREED_TITLE, objectPath: 'translation.title', type: STRING, tabIndex: 1},
        breedHistory: {title: BREED_HISTORY, objectPath: 'translation.breedHistory', type: STRING, tabIndex: 4},
        behaviour: {title: BEHAVIOUR, objectPath: 'translation.behaviour', type: STRING, tabIndex: 18},
        isTechnical: {title: BREED_IS_TECHNICAL, objectPath: 'technical', type: BOOLEAN, tabIndex: 19}
    }
}